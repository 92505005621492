import "@ai4/form-builder/dist/index.css";
import { Modal } from "react-bootstrap";

function DateConfirmationModal(props) {
  const {
    showDateAlertModal,
    setShowDateAlertModal,
    prevFromDateValue,
    prevToDateValue,
    setToDateValue,
    setFromDateValue,
    applyFilterCall,
  } = props;
  return (
    <>
      <Modal
        show={showDateAlertModal}
        className="date-confirmation-modal"
        centered={true}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure, more than 7 days?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            if date time difference is more than 7 days <b>api could be slower</b>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              setToDateValue(prevToDateValue);
              setFromDateValue(prevFromDateValue);
              setShowDateAlertModal(false);
            }}
          >
            no
          </button>
          <button className="btn btn-secondary" onClick={() => {
            setShowDateAlertModal(false);
            applyFilterCall(); return void (0);
          }}>
            yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DateConfirmationModal;
