import "@ai4/form-builder/dist/index.css";

function PerformanceNodes(props) {
  const { columns, returnValuesRows } = props;

  return (
    <>
      <div className="react-bootstrap-table">
        <table className="table table-bordered columns-toggle-table table-hover filterTable wp-list-table widefat fixed striped table-view-list posts">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th tabIndex={0} key={`thead-${index}`}>
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{returnValuesRows()}</tbody>
        </table>
      </div>
    </>
  );
}

export default PerformanceNodes;
