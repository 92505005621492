import { lazy } from "react";
import { authRoles } from "src/app/roles";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const ssoTenantsRegistrationsRoutes = [
    {
        path: "sso-tenants-registrations/List",
        element: <TenantsRegistrations />,
        auth: authRoles.admin
    },
];

function TenantsRegistrations() {
    return <Crud module='sso-tenants-registrations' entity='registrations' />;
};

export default ssoTenantsRegistrationsRoutes;
