import { authRoles } from "src/app/roles";
import Dashboard from "./Dashboard";

const dashboardRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
    auth: authRoles.admin,
  }
];

export default dashboardRoutes;
