import { useState } from "react";
import "@ai4/form-builder/dist/index.css";
import { formatDate } from "../../../../@bootstrap/features/utilities";

function LogsTabsTrace(props) {
  const [isRequestLogActive, setIsRequestLogActive] = useState<boolean>(true);
  const { details, isApiLog } = props;

  return (
    <>
      {/* 56703f4c-4b74-4ccc-eeb0-08daa61f630e */}
      {/* TABS */}
      {typeof details.data !== "undefined" &&
        details.data !== null &&
        details.data !== "" ? (
        <div className="row mb-5">
          <div className="col-12">
            {/* tabs button */}
            <div className="logs-tab-container">
              <div className="logs-tab-container__label">
                {isApiLog ? "Api log" : "Runtime log"}
              </div>
              <div>
                <button
                  className={isRequestLogActive ? "btn active" : "btn"}
                  onClick={() => setIsRequestLogActive(true)}
                >
                  Request
                </button>
                <button
                  className={isRequestLogActive ? "btn" : "btn active"}
                  onClick={() => setIsRequestLogActive(false)}
                >
                  Response
                </button>
              </div>

              {/* Request tab */}
              <div
                className={
                  isRequestLogActive
                    ? "logs-tab-request"
                    : "logs-tab-request d-none"
                }
              >
                <div>dateTime: {formatDate(details.data.requestTime)}</div>
                {typeof details.data.user !== "undefined" &&
                  details.data.user !== null &&
                  details.data.user !== "" ? (
                  <div className="mb-3">User:{details.data.user}</div>
                ) : (
                  ""
                )}
                <div className="row">
                  {typeof details.data.domain === "undefined" ||
                    details.data.domain === null ||
                    details.data.domain === "" ? (
                    ""
                  ) : (
                    <div className="col-12 col-md-6">
                      URL: {details.data.domain}
                    </div>
                  )}
                  {typeof details.data.node === "undefined" ||
                    details.data.node === null ||
                    details.data.node === "" ? (
                    ""
                  ) : (
                    <div className="col-12 col-md-6">
                      Node: {details.data.node}
                    </div>
                  )}
                </div>
                <div className="row mb-3">
                  {typeof details.data.requestType === "undefined" ||
                    details.data.requestType === null ||
                    details.data.requestType === "" ? (
                    ""
                  ) : (
                    <div className="col-12 col-md-6">
                      Request Type: {details.data.requestType}
                    </div>
                  )}
                  {typeof details.data.resource === "undefined" ||
                    details.data.resource === null ||
                    details.data.resource === "" ? (
                    ""
                  ) : (
                    <div className="col-12 col-md-6">
                      Resource: {details.data.resource}
                    </div>
                  )}
                </div>
                {typeof details.data.requestJson === "undefined" ||
                  details.data.requestJson === null ||
                  details.data.requestJson === "" ? (
                  ""
                ) : (
                  <>
                    <div className="mb-1">Request json:</div>
                    <div className="logs-tab-json mb-3">
                      {details.data.requestJson}
                    </div>
                  </>
                )}
                {typeof details.data.requestHeaders === "undefined" ||
                  details.data.requestHeaders === null ||
                  details.data.requestHeaders === "" ? (
                  ""
                ) : (
                  <>
                    <div className="mb-1">Request header:</div>
                    <div className="logs-tab-json">
                      {details.data.requestHeaders}
                    </div>
                  </>
                )}
              </div>
              {/* Response tab */}
              <div
                className={
                  isRequestLogActive
                    ? "logs-tab-response d-none"
                    : "logs-tab-response"
                }
              >
                <div className="row">
                  {typeof details.data.processTime === "undefined" ||
                    details.data.processTime === null ||
                    details.data.processTime === "" ? (
                    ""
                  ) : (
                    <div className="col-8 col-md-6">
                      process time {details.data.processTime} mils
                    </div>
                  )}
                  {typeof details.data.success === "undefined" ||
                    details.data.success === null ||
                    details.data.success === "" ? (
                    ""
                  ) : (
                    <div className="col-4 col-md-6">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="succes"
                          name="succes"
                          tabIndex={-1}
                          defaultChecked={details.data.success}
                        />
                        <label htmlFor="succes" data-content="success">
                          success
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                {typeof details.data.responseLength === "undefined" ||
                  details.data.responseLength === null ||
                  details.data.responseLength === "" ? (
                  ""
                ) : (
                  <div className="mb-3">
                    response length: {details.data.responseLength} bytes
                  </div>
                )}
                {typeof details.data.responseJson === "undefined" ||
                  details.data.responseJson === null ||
                  details.data.responseJson === "" ? (
                  ""
                ) : (
                  <>
                    <div className="mb-1">Response json:</div>
                    <div className="logs-tab-json mb-3">
                      {details.data.responseJson}
                    </div>
                  </>
                )}
                {typeof details.data.responseHeaders === "undefined" ||
                  details.data.responseHeaders === null ||
                  details.data.responseHeaders === "" ? (
                  ""
                ) : (
                  <>
                    <div className="mb-1">Response header:</div>
                    <div className="logs-tab-json">
                      {details.data.responseHeaders}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default LogsTabsTrace;
