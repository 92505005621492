import "@ai4/form-builder/dist/index.css";
import { useState } from "react";
import DateConfirmationModal from "./modal/DateConfirmationModal";
import Spinner from "../../../@bootstrap/components/Spinner";
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'

function DashboardForm(props) {
  const {
    tenants,
    tenantValue,
    setTenantValue,
    requestUsage,
    requestErrorsByNode,
    requestPerformanceByNode,
    setUsage,
    setErrorsByNode,
    setPerformanceByNode,
    fromDateValue,
    setFromDateValue,
    toDateValue,
    setToDateValue,
    defaultMaxDateFormat,
    defaultMinDateFormat,
    setTenantName,
    tenantName,
    setShowModalTenants,
  } = props;
  const [prevFromDateValue, setPrevFromDateValue] = useState<string>(defaultMinDateFormat);
  const [prevToDateValue, setPrevToDateValue] = useState<string>(defaultMaxDateFormat);
  const [showDateAlertModal, setShowDateAlertModal] = useState<boolean>(false);
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);
  const [isNoResults, setIsNoResults] = useState<boolean>(false);
  const [isErrorFormatDate, setIsErrorFormatDate] = useState<boolean>(false);

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const applyFilterAction = () => {
    const dateFrom = new Date(fromDateValue);
    const dateTo = new Date(toDateValue);

    if (
      (dateTo.getDate() - dateFrom.getDate() > 7 ||
        dateTo.getMonth() !== dateFrom.getMonth() ||
        dateTo.getFullYear() !== dateFrom.getFullYear())
    ) {
      setShowDateAlertModal(true);
      setIsErrorFormatDate(false);
    } else {
      applyFilterCall();
    }
  };

  const applyFilterCall = () => {
    let isUsageFetching = true;
    let isErrorsFetching = true;
    let hasUsageErrors = false;
    let hasErrorsError = false;

    setSpinnerShow(true);
    localStorage.setItem("defaultTenantsName", tenantName);
    localStorage.setItem("defaultTenants", tenantValue);

    requestUsage(tenantValue, fromDateValue, toDateValue)
      .then((data) => {
        setUsage(data);
        if (data.length === 0) {
          hasUsageErrors = true;
        } else {
          hasUsageErrors = false;
        }
      })
      .catch((data) => {
        hasUsageErrors = true;
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      })
      .finally((isUsageFetching = false));

    if (!isUsageFetching) {
      requestErrorsByNode(tenantValue, fromDateValue, toDateValue)
        .then((data) => {
          setErrorsByNode(data);
          if (data.length === 0) {
            hasErrorsError = true;
          } else {
            hasErrorsError = false;
          }
        })
        .catch((data) => {
          hasErrorsError = true;
          setIsErrorShow(true);
          setErrorMessage(data.messages);
          setErrorCode(data.requestId);
        })
        .finally((isErrorsFetching = false));
    }

    if (!isUsageFetching && !isErrorsFetching) {
      requestPerformanceByNode(tenantValue, fromDateValue, toDateValue)
        .then((data) => {
          setPerformanceByNode(data);
          if (data.length === 0 && hasUsageErrors && hasErrorsError) {
            setIsNoResults(true);
          } else {
            setIsNoResults(false);
          }
        })
        .catch((data) => {
          if (hasUsageErrors && hasErrorsError) {
            setIsNoResults(true);
          } else {
            setIsNoResults(false);
          }
          setIsErrorShow(true);
          setErrorMessage(data.messages);
          setErrorCode(data.requestId);
        })
        .finally(() => {
          setSpinnerShow(false);
        });
    }
  }

  const onDateBlur = () => {
    const dateFrom = new Date(fromDateValue);
    const dateTo = new Date(toDateValue);

    if (
      (dateFrom.getDate() > dateTo.getDate() &&
        dateFrom.getMonth() === dateTo.getMonth() &&
        dateFrom.getFullYear() === dateTo.getFullYear()) ||
      (dateFrom.getMonth() > dateTo.getMonth() &&
        dateFrom.getFullYear() === dateTo.getFullYear()) ||
      dateFrom.getFullYear() > dateTo.getFullYear()
    ) {
      setIsErrorFormatDate(true);
      setFromDateValue(prevFromDateValue);
      setToDateValue(prevToDateValue);
      setTimeout(() => {
        setIsErrorFormatDate(false);
      }, 5000);
    }
  };
  return (
    <>
      <div className="row justify-content-between mb-5">
        <div className="col-12 col-md-4 col-lg-4">
          <div className="row align-items-center mb-2">
            <div className="col-12 col-md-2">Tenant</div>
            <div className="col-10 col-md-8">
              <select
                name="tenants"
                onChange={(e) => {
                  setTenantValue(e.target.value);
                  setTenantName(tenants[e.target.selectedIndex].name);
                }}
                value={tenantValue}
              >
                {typeof tenants !== "undefined" && tenants !== null
                  ? tenants.map((tenant, index) => {
                    return <option key={index} value={tenant.id}>{tenant.name}</option>;
                  })
                  : ""}
              </select>
            </div>
            <div className="col-2">
              <button className="btn btn-secondary show-tenant-details" onClick={() => setShowModalTenants(true)}>
                <i className="nav-icon i-Information"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 col-lg-6">
          <div className="row align-items-center">
            <div className="col-12 col-md-4 mb-2">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <label htmlFor="fromDateId">From</label>
                </div>
                <div className="col-12 col-md-8">
                  <input
                    type="date"
                    value={fromDateValue}
                    max={toDateValue}
                    onChange={(e) => setFromDateValue(e.target.value)}
                    onFocus={() => setPrevFromDateValue(fromDateValue)}
                    onBlur={() => onDateBlur()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <label htmlFor="toDateId">To</label>
                </div>
                <div className="col-12 col-md-8">
                  <input
                    type="date"
                    value={toDateValue}
                    min={fromDateValue}
                    onChange={(e) => setToDateValue(e.target.value)}
                    onFocus={() => setPrevToDateValue(toDateValue)}
                    onBlur={() => onDateBlur()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <button className="btn btn-secondary" onClick={() => applyFilterAction()}>
                Apply filters
              </button>
            </div>
            {isErrorFormatDate ? (
              <div className="format-date-error">
                Uncorrect date selection. <b>From Date</b> can't be bigger of than <b>To Date</b>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isNoResults === true ? <div className="dashboard-errors">No results founded</div> : ""}

      <DateConfirmationModal
        showDateAlertModal={showDateAlertModal}
        setShowDateAlertModal={setShowDateAlertModal}
        prevFromDateValue={prevFromDateValue}
        prevToDateValue={prevToDateValue}
        setToDateValue={setToDateValue}
        setFromDateValue={setFromDateValue}
        applyFilterCall={applyFilterCall}
      />

      <Spinner show={spinnerShow} />

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />
    </>
  );
}

export default DashboardForm;
