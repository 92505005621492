import "@ai4/form-builder/dist/index.css";

function Spinner(props) {
  const { show } = props;
  return (
    <>
      {show ? (
        <div className="module-loader">
          <div className="spinner"></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Spinner;
