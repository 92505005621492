import "@ai4/form-builder/dist/index.css";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useEffect, useState, useRef } from "react";
import PaginationApi from "./PaginationApi";
import Pagination from "./Pagination";

function Table(props) {
  const {
    isApplicationFilterActive,
    isDisabledButton,
    filters,
    setFilter,
    columns,
    onColumnToggle,
    returnValuesRows,
    defaultPage,
    setDefaultPage,
    paginationValue,
    callApiGet,
    paginationTotalCount,
    paginationTotalPages,
    paginationCurrentPage,
    apiRequestValues,
    selectedCategory,
    setSelectedCategory,
    categories,
    isLogTrace,
    setPaginationCurrentPage,
    isStaticPagination,
    startPaginationLabel,
    endPaginationLabel
  } = props;

  const [isOpenPagination, setIsOpenPagination] = useState<boolean>(false);
  const [levelFilter, setLevelFilter] = useState<string>("All");

  function useOutsideAlerter(ref) {
    useEffect(() => {
      // closePagination menu if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpenPagination(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (isApplicationFilterActive) {
      setLevelFilter("All");
    }
  }, [apiRequestValues, isApplicationFilterActive]);

  const paginationRef = useRef(null);
  useOutsideAlerter(paginationRef);

  const togglePaginationMenu = () => {
    setIsOpenPagination(!isOpenPagination);
  };

  const onChangePaginationValue = (e) => {
    setLevelFilter("All");
    setDefaultPage(e.target.value);
    setIsOpenPagination(false);
    callApiGet(
      e.target.value,
      "1",
      apiRequestValues[0],
      apiRequestValues[1],
      apiRequestValues[2],
      apiRequestValues[3],
      apiRequestValues[4],
      apiRequestValues[5],
      apiRequestValues[6],
      apiRequestValues[7]
    );
  };

  const onChangePage = (e, isNumberButton) => {
    setLevelFilter("All");
    const pageArgument = isNumberButton ? e.target.value : e;
    callApiGet(
      defaultPage,
      pageArgument,
      apiRequestValues[0],
      apiRequestValues[1],
      apiRequestValues[2],
      apiRequestValues[3],
      apiRequestValues[4],
      apiRequestValues[5],
      apiRequestValues[6],
      apiRequestValues[7]
    );
  };

  const getPaginationNumber = () => {
    let content: Array<any> = [];
    for (let index = 1; index <= paginationTotalPages; index++) {
      content.push(
        <li key={index}
          className={
            paginationCurrentPage === index ? "active page-item" : "page-item"
          }
          title={index.toString()}
        >
          <button
            className="page-link"
            value={index}
            onClick={(e) => onChangePage(e, true)}
          >
            {index}
          </button>
        </li>
      );
    }
    return content;
  };

  const getTotalCountLabel = () => {
    let currentResultStart = 1;
    let currentResultEnd = defaultPage;
    if (paginationCurrentPage > 1) {
      for (let index = 0; index < paginationCurrentPage; index++) {
        currentResultStart = currentResultStart + 50;
        currentResultEnd = currentResultStart + currentResultEnd;
      }
    }
    return ` Risultati da ${currentResultStart} a ${currentResultEnd}`;
  };

  const getValuesDropdown = (element, elementSize) => {
    const arrayValue: Array<string> = [];
    for (let index = 0; index < elementSize.size; index++) {
      arrayValue.push(element.next().value);
    }
    return arrayValue;
  };

  let category: any = [];
  let categorySize: any = [];
  if (typeof categories !== "undefined" && categories !== null) {
    categorySize = new Set<string>(categories.map((value) => value.category));
    category = categorySize.values();
  }

  return (
    <>
      <div className="d-flex justify-content-end align-items-center mb-3 levels-filter-container">
        {filters !== false ? (
          <div className="flex-grow-1 align-items-center">
            <div className="d-flex">
              <span className="m-2">Levels</span>
              <ul className="pagination react-bootstrap-table-page-btns-ul">
                {filters.map((filter, index) => (
                  <li key={index}
                    className={
                      levelFilter === filter ? "page-item active" : "page-item"
                    }
                  >
                    <button
                      className="page-link"
                      value={filter}
                      onClick={(el: any) => {
                        setFilter(el);
                        setLevelFilter(el.target.value);
                      }}
                      disabled={isDisabledButton}
                    >
                      {filter}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            {typeof categories !== "undefined" &&
              categories !== null &&
              !isLogTrace ? (
              <div className="form-category-container d-flex mt-3">
                <div className="m-2">Category</div>
                <select
                  name="category"
                  id="categoryId"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  disabled={returnValuesRows().length === 0}
                >
                  <option value="">--Select a category--</option>
                  {getValuesDropdown(category, categorySize).map((value) => {
                    return <option value={value}>{value}</option>;
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div className={returnValuesRows().length > 0 ? "" : "d-none"}>
          <Dropdown className="d-flex align-items-center">
            <Dropdown.Toggle id="dropdown-custom-components">
              Colonne...
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ padding: "10px" }}>
              {columns.map((column) => (
                <Form.Check
                  type="checkbox"
                  key={column.name}
                  label={column.name}
                  id={column.name}
                  checked={column.toggle}
                  aria-checked={column.toggle ? "true" : "false"}
                  onChange={() => onColumnToggle(column.name)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className={returnValuesRows().length > 0 ? "" : "d-none"}>
        <div className="react-bootstrap-table">
          <table className="table table-bordered columns-toggle-table table-hover filterTable wp-list-table widefat fixed striped table-view-list posts">
            <thead>
              <tr>
                {columns.map((column, index) =>
                  column.toggle ? <th key={index} tabIndex={0}>{column.name}</th> : <th key={index} style={{ display: "none" }}></th>
                )}
              </tr>
            </thead>
            <tbody>{returnValuesRows()}</tbody>
          </table>
        </div>

        {isStaticPagination ?
          <Pagination
            isApplicationFilterActive={isApplicationFilterActive}
            defaultPage={defaultPage}
            setDefaultPage={setDefaultPage}
            paginationValue={paginationValue}
            callApiGet={callApiGet}
            setPaginationCurrentPage={setPaginationCurrentPage}
            paginationTotalCount={paginationTotalCount}
            paginationTotalPages={paginationTotalPages}
            paginationCurrentPage={paginationCurrentPage}
            apiRequestValues={apiRequestValues}
            categories={categories}
            startPaginationLabel={startPaginationLabel}
            endPaginationLabel={endPaginationLabel}
          /> :
          <PaginationApi
            isApplicationFilterActive={isApplicationFilterActive}
            defaultPage={defaultPage}
            setDefaultPage={setDefaultPage}
            paginationValue={paginationValue}
            callApiGet={callApiGet}
            paginationTotalCount={paginationTotalCount}
            paginationTotalPages={paginationTotalPages}
            paginationCurrentPage={paginationCurrentPage}
            apiRequestValues={apiRequestValues}
            categories={categories}
          />
        }
      </div>
    </>
  );
}

export default Table;
