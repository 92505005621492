import ReactEcharts from "echarts-for-react";

const CakeGraph = ({ height, dataValue, type, handleMoreDetailsDataPie }) => {
  const option = {
    color: [
      "red",
      "pink",
      "blue",
      "grey",
      "orange",
      "#62549c",
      "brown",
      "green",
      "#9181bd",
      "yellow",
    ],
    tooltip: {
      show: true,
      backgroundColor: "#ffffff",
    },

    series: [
      {
        // name: type === "error" ? "Errors by node:" : "Average process time:",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: [...dataValue],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <ReactEcharts
      style={{ height: height }}
      option={option}
      onEvents={{ click: (e) => handleMoreDetailsDataPie(e) }}
    />
  );
};

export default CakeGraph;
