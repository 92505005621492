import "@ai4/form-builder/dist/index.css";
import { Modal } from "react-bootstrap";
import PerformanceNodes from "../PerformanceNodes";
import { useState, useEffect } from "react";
import LogsApiList from "./LogsApiList";
import { pad } from "../../../../@bootstrap/features/utilities";

function FullDataModal(props) {
  const {
    showFullDataModal,
    setShowFullDataModal,
    performanceByNode,
    errorsByNode,
    fullDataModalType,
    tenantValue,
    tenantName,
    fromDateValue,
    toDateValue,
    handleMoreDetailsData,
    apiLogs,
    setApiLogs,
    selectedNode,
    thereIsBackModal,
    selectedNodeName,
  } = props;
  const [moreDetailsData, setMoreDetailsData] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<any>>([]);
  const returnValuesRows = () => {
    if (fullDataModalType === "performance") {
      return performanceByNode.map((data, index) => (
        <tr key={index}>
          <td
            data-node={data.nodeUniqueId}
            data-node-name={data.node}
            onClick={(e) => {
              handleMoreDetailsData(e);
            }}
          >
            {data.node}
          </td>
          <td
            data-node={data.nodeUniqueId}
            data-node-name={data.node}
            onClick={(e) => {
              handleMoreDetailsData(e);
            }}
          >
            {data.requestsCount}
          </td>
          <td
            data-node={data.nodeUniqueId}
            data-node-name={data.node}
            onClick={(e) => {
              handleMoreDetailsData(e);
            }}
          >
            <div
              className="process-time"
              data-node={data.nodeUniqueId}
              data-node-name={data.node}
              onClick={(e) => {
                handleMoreDetailsData(e);
              }}
            >
              {data.averageProcessTime}
              <span
                data-node={data.nodeUniqueId}
                data-node-name={data.node}
                onClick={(e) => {
                  handleMoreDetailsData(e);
                }}
                className={
                  data.averageProcessTime < 100
                    ? "badge badge-grey"
                    : data.averageProcessTime < 250
                      ? "badge badge-yellow"
                      : "badge badge-red"
                }
              >
                {data.averageProcessTime < 100
                  ? "Good"
                  : data.averageProcessTime < 250
                    ? "Med"
                    : "Bad"}
              </span>
            </div>
          </td>
        </tr>
      ));
    } else {
      return errorsByNode.map((data, index) => (
        <tr key={index}>
          <td
            data-node={data.nodeUniqueId}
            data-node-name={data.node}
            onClick={(e) => {
              handleMoreDetailsData(e);
            }}
          >
            {data.node}
          </td>
          <td
            data-node={data.nodeUniqueId}
            data-node-name={data.node}
            onClick={(e) => {
              handleMoreDetailsData(e);
            }}
          >
            {data.requestsCount}
          </td>
          <td
            data-node={data.nodeUniqueId}
            data-node-name={data.node}
            onClick={(e) => {
              handleMoreDetailsData(e);
            }}
          >
            {data.errorsCount}
          </td>
        </tr>
      ));
    }
  };

  useEffect(() => {
    if (fullDataModalType === "performance") {
      setColumns(["Nodes", "Requests count", "Average process time"]);
    } else {
      setColumns(["Nodes", "Requests count", "Errors count"]);
    }
  }, [fullDataModalType]);

  return (
    <>
      <Modal
        show={showFullDataModal}
        animation={false}
        fullscreen={"xl-down"}
        onHide={() => {
          setShowFullDataModal(false);
          setTimeout(() => {
            setApiLogs(false);
            setMoreDetailsData(false);
          }, 300);
        }}
        className="full-data-modal"
        size="xl"
        centered={true}
      >
        <Modal.Header closeButton>
          {moreDetailsData ? (
            <Modal.Title>Logs Details</Modal.Title>
          ) : (
            <>
              <span className="fulldata-modal-header">
                <div>
                  Tenant: <b>{tenantName}</b>
                </div>
                <div>
                  Period:{" "}
                  <b>
                    {pad(new Date(fromDateValue).getDate()) +
                      "-" +
                      pad(new Date(fromDateValue).getMonth()) +
                      "-" +
                      new Date(fromDateValue).getFullYear()}{" "}
                  </b>
                  to{" "}
                  <b>
                    {pad(new Date(toDateValue).getDate()) +
                      "-" +
                      pad(new Date(toDateValue).getMonth()) +
                      "-" +
                      new Date(toDateValue).getFullYear()}
                  </b>
                </div>
              </span>
              {apiLogs === true ? (
                <span className="fulldata-modal-header">
                  <div>
                    Node: <b>{selectedNodeName}</b>
                  </div>
                  <div>
                    Success: <b>{fullDataModalType === "performance" ? "true" : "false"}</b>
                  </div>
                </span>
              ) : (
                ""
              )}
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          {apiLogs === false ? (
            <PerformanceNodes columns={columns} returnValuesRows={returnValuesRows} />
          ) : (
            <LogsApiList
              tenantValue={tenantValue}
              fromDateValue={fromDateValue}
              toDateValue={toDateValue}
              selectedNode={selectedNode}
              setApiLogs={setApiLogs}
              thereIsBackModal={thereIsBackModal}
              fullDataModalType={fullDataModalType}
              moreDetailsData={moreDetailsData}
              setMoreDetailsData={setMoreDetailsData}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FullDataModal;
