import { Toast, ToastContainer } from "react-bootstrap";
import { createPortal } from "react-dom";

interface Props {
    errors: string;
    errorCode: string;
    isErrorShow: boolean
    setIsErrorShow: (value: boolean) => void;
    setErrorMessage: (value: string) => void;
    setErrorCode: (value: string) => void;
}

export function ErrorApiAlert(props: Props) {
    const { errors, errorCode, isErrorShow, setIsErrorShow, setErrorMessage, setErrorCode } = props;
    let errorMessageToShow = "Generic Errors";
    let errorCodeToShow = "";
    if (errors) {
        errorMessageToShow = errors;
    }
    if (errorCode) {
        errorCodeToShow = errorCode;
    }

    const onCloseErrorAlert = () => {
        setIsErrorShow(false);
        setErrorMessage("");
        setErrorCode("");
    }

    return createPortal(
        <>
            {
                isErrorShow ?
                    <ToastContainer className="p-3 position-fixed" style={{ zIndex: 2000 }} position={"bottom-end"}>
                        <Toast onClose={onCloseErrorAlert} bg='white'>
                            <Toast.Header>
                                <div style={{ width: "10px", height: "15px", backgroundColor: "red", marginRight: "5px" }}>
                                </div>
                                <strong className="me-auto">Error</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <div> <b>Message: </b> {errorMessageToShow}</div>
                                <div> {errorCodeToShow !== "" ? <b>RequestId: </b> : ""} {errorCodeToShow}</div>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>
                    : ""
            }
        </>,
        document.body
    );

}
export default ErrorApiAlert;

