import "@ai4/form-builder/dist/index.css";
import { useEffect, useState, useRef } from "react";

function Pagination(props) {
    const {
        isApplicationFilterActive,
        defaultPage,
        setDefaultPage,
        paginationValue,
        paginationTotalCount,
        paginationTotalPages,
        paginationCurrentPage,
        apiRequestValues,
        categories,
        setPaginationCurrentPage,
        startPaginationLabel,
        endPaginationLabel
    } = props;

    const [isOpenPagination, setIsOpenPagination] = useState<boolean>(false);
    const [levelFilter, setLevelFilter] = useState<string>("All");

    function useOutsideAlerter(ref) {
        useEffect(() => {
            // closePagination menu if clicked on outside of element
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpenPagination(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        if (isApplicationFilterActive) {
            setLevelFilter("All");
        }
    }, [apiRequestValues, isApplicationFilterActive]);

    useEffect(() => {
        if (
            paginationCurrentPage > paginationTotalPages &&
            paginationTotalPages !== "" &&
            paginationCurrentPage !== ""
        ) {
            setPaginationCurrentPage(paginationTotalPages)
        }
    }, [paginationTotalPages]);

    const paginationRef = useRef(null);
    useOutsideAlerter(paginationRef);

    const togglePaginationMenu = () => {
        setIsOpenPagination(!isOpenPagination);
    };

    const onChangePaginationValue = (e) => {
        setDefaultPage(e.target.value);
        setIsOpenPagination(false);
    };

    const onChangePage = (e, isNumberButton) => {
        if (isNumberButton) {
            setPaginationCurrentPage(e.target.value)
        } else {
            setPaginationCurrentPage(e.toString())
        }
    };

    const getPaginationNumber = () => {
        let content: Array<any> = [];
        for (let index = 1; index <= paginationTotalPages; index++) {
            content.push(
                <li key={index}
                    className={
                        parseInt(paginationCurrentPage) === index ? "active page-item" : "page-item"
                    }
                    title={index.toString()}
                >
                    <button
                        className="page-link"
                        value={index}
                        onClick={(e) => onChangePage(e, true)}
                    >
                        {index}
                    </button>
                </li>
            );
        }
        return content;
    };

    const getTotalCountLabel = () => {
        return ` Risultati da ${startPaginationLabel + 1} a ${endPaginationLabel}`;
    };

    let category: any = [];
    let categorySize: any = [];
    if (typeof categories !== "undefined" && categories !== null) {
        categorySize = new Set<string>(categories.map((value) => value.category));
        category = categorySize.values();
    }

    return (
        <>
            <div className="row react-bootstrap-table-pagination">
                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    <button
                        className="btn btn-default btn-secondary dropdown-toggle"
                        onClick={togglePaginationMenu}
                    >
                        {defaultPage}
                    </button>
                    <ul
                        ref={paginationRef}
                        className={
                            isOpenPagination ? "dropdown-menu open show" : "dropdown-menu"
                        }
                    >
                        {paginationValue.map((page, index) => (
                            <li
                                key={index}
                                className="dropdown-item"
                                value={page}
                                onClick={(e) => onChangePaginationValue(e)}
                                style={{ cursor: "pointer" }}
                            >
                                {page}
                            </li>
                        ))}
                    </ul>
                    {getTotalCountLabel()}
                    <span className="react-bootstrap-table-pagination-total">
                        {paginationTotalCount > 1
                            ? ` di ${paginationTotalCount} totali`
                            : ""}
                    </span>
                </div>
                <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    <ul className="pagination react-bootstrap-table-page-btns-ul">
                        {paginationCurrentPage > 1 ? (
                            <li className="page-item prev-button" title="prevPage">
                                <button
                                    className="page-link"
                                    value="prevPage"
                                    onClick={() =>
                                        onChangePage(parseInt(paginationCurrentPage) - 1, false)
                                    }
                                >
                                    Prec.
                                </button>
                            </li>
                        ) : (
                            ""
                        )}

                        {getPaginationNumber()}
                        {paginationTotalPages !== paginationCurrentPage ? (
                            <li className="page-item next-button" title="nextPage">
                                <button
                                    className="page-link"
                                    value="nextPage"
                                    onClick={() =>
                                        onChangePage(parseInt(paginationCurrentPage) + 1, false)
                                    }
                                >
                                    Succ.
                                </button>
                            </li>
                        ) : (
                            ""
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Pagination;
