export const navigations = [
    {
        name: "Tenants",
        type: "dropDown",
        icon: "nav-icon i-Administrator",
        sub: [
            {
                icon: "i-Administrator",
                name: "Attivi",
                path: "/tenants/List",
                type: "link"
            },
            {
                icon: "i-Administrator",
                name: "Richieste di attivazione",
                path: "/sso-tenants-registrations/List",
                type: "link"
            },
        ]
    },
    {
        name: "Access control",
        type: "dropDown",
        icon: "nav-icon i-Administrator",
        sub: [
            {
                icon: "i-Administrator",
                name: "Users",
                path: "/users/List",
                type: "link"
            },
        ]
    },
    {
        name: "Background Jobs",
        type: "extLink",
        icon: "nav-icon i-Administrator",
        path: "https://ai4-api-wppa.pikkart.com/jobs",
        target: '_blank'
    },
    {
        name: "Tools",
        type: "dropDown",
        icon: "i-Gear",
        sub: [
            {
                icon: "i-Unlock-2",
                name: "Navigation Editor",
                path: "/tools/navigation-editor",
                type: "link"
            },
            {
                icon: "i-Unlock-2",
                name: "Form Builder",
                path: "/tools/form-builder",
                type: "link"
            },
        ]
    },
    {
        name: "Configurazioni",
        type: "dropDown",
        icon: "i-Gear",
        sub: [
            {
            icon: "i-Management",
            name: "Ruoli",
            path: "/config/roles/list",
            type: "link"
            },
            {
            icon: "i-Unlock-2",
            name: "Permessi",
            path: "/config/permissions/main",
            type: "link"
            }
        ]
    }
];
  
