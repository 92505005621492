import { authRoles } from "src/app/roles";
import Tenants from "./Tenants";

const tenantsRoutes = [
    {
        path: "tenants/List",
        element: <Tenants />,
        auth: authRoles.admin
    },
];

export default tenantsRoutes;
