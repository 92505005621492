/***
 * @template
 * warning: this file exists in base repo, check for wrong overrides
 */

import React from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "src/@bootstrap/auth/AuthGuard";
import sessionsRoutes from "src/@bootstrap/views/sessions/sessionsRoutes";
import toolsRoutes from "src/@bootstrap/views/tools/toolsRoutes";
import configRoutes from "src/@bootstrap/views/config/configRoutes";
import demoRoutes from "src/app/views/@demo/demoRoutes";
import accountRoutes from "src/@bootstrap/views/account/accountRoutes";
import usersRoutes from "src/app/views/@users/usersRoutes";
import tenantsRoutes from "src/app/views/tenants/tenantsRoutes";
import ssoTenantsRegistrationsRoutes from "src/app/views/sso-tenants-registrations/ssoTenantsRegistrationsRoutes";
import logsRoutes from "src/app/views/logs/logsRoutes";
import dashboardRoutes from "src/app/views/dashboard/dashboardRoutes";

import { authRoles } from "src/app/roles";

const indexRoute = [
    {
        path: "",
        exact: true,
        element: <Navigate replace to="/Dashboard" />,
        auth: authRoles.guest
    }
];

const errorRoute = [
    {
        id: '404',
        path: "404",
        element: <Navigate to="/session/404" />
    }
];

const routes = [
    ...sessionsRoutes,
    {
        id: 'root',
        path: "/*",
        element: <AuthGuard />,
        children: [
            ...indexRoute,
            ...errorRoute,
            ...toolsRoutes,
            ...configRoutes,
            ...accountRoutes,
            ...usersRoutes,
            ...tenantsRoutes,
            ...ssoTenantsRegistrationsRoutes,
            ...demoRoutes,
            ...logsRoutes,
            ...logsRoutes,
            ...dashboardRoutes,
        ]
    }
];

export default routes;
