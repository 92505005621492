import "@ai4/form-builder/dist/index.css";
import { useState, useCallback } from "react";
import { useDataRequest } from "@ai4/data-request";
import { Modal } from "react-bootstrap";
import LogsTabsTrace from "./LogsTabsTrace";
import ErrorApiAlert from '../../../../@bootstrap/components/alert/ErrorApiAlert'

function LogsModal(props) {
  const { requestId, showModal, setShowModal, logsType, tenantValue } = props;
  const handleClose = () => setShowModal(false);
  const { useRestRequest } = useDataRequest();
  const [isApiLog, setIsApiLog] = useState<boolean>(false);
  const [isNoResult, setIsNoResult] = useState<boolean>(false);

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");


  const [reqRuntimeLogs, resRuntimeLogs] = useRestRequest({
    path: `api/{ver}/logs/api-runtime-log/${requestId}?TenantId=${tenantValue}`,
    method: "GET",
    jwt: true,
  });

  const [reqApiLog, resApiLog] = useRestRequest({
    path: `api/{ver}/logs/api-log/${requestId}?TenantId=${tenantValue}`,
    method: "GET",
    jwt: true,
  });

  const requestRuntimeLogs = useCallback(
    async (requestId, tenantValue) => {
      const reqRuntimeLogsDetail = (await reqRuntimeLogs({
        path: `api/{ver}/logs/api-runtime-log/${requestId}?TenantId=${tenantValue}`,
        method: "GET",
        jwt: true,
      })) as any;
      return reqRuntimeLogsDetail;
    },
    [reqRuntimeLogs]
  );

  const requesApiLogs = useCallback(
    async (requestId, tenantValue) => {
      const reqApiLogDetail = (await reqApiLog({
        path: `api/{ver}/logs/api-log/${requestId}?TenantId=${tenantValue}`,
        method: "GET",
        jwt: true,
      })) as any;
      setIsApiLog(true);
      return reqApiLogDetail;
    },
    [reqApiLog]
  );

  const getModalLogs = (requestId, tenantValue) => {
    setIsNoResult(false);
    if (logsType === "apiRuntimeLogs") {
      requestRuntimeLogs(requestId, tenantValue)
        .then((data) => {
          if (Object.keys(data).length === 0) {
            setIsNoResult(true);
          }
        })
        .catch((data) => {
          setIsNoResult(true);
          setIsErrorShow(true);
          setErrorMessage(data.messages);
          setErrorCode(data.requestId);
        });
    }
    if (logsType === "logApi") {
      requesApiLogs(requestId, tenantValue)
        .then((data) => {
          if (Object.keys(data).length === 0) {
            setIsNoResult(true);
          }
        })
        .catch((data) => {
          setIsNoResult(true);
          setIsErrorShow(true);
          setErrorMessage(data.messages);
          setErrorCode(data.requestId);
        });
    }
  };

  const details: any = isApiLog ? resApiLog : resRuntimeLogs;

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        onEnter={() => getModalLogs(requestId, tenantValue)}
        className="logs-modal"
        size="xl"
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Logs Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isNoResult ? (
            <LogsTabsTrace details={details} isApiLog={isApiLog} />
          ) : (
            <div className="logs-modal__error">No resault founded...</div>
          )}
        </Modal.Body>
      </Modal>

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />
    </>
  );
}

export default LogsModal;
