import "@ai4/form-builder/dist/index.css";
import { useState, useCallback } from "react";
import { useDataRequest } from "@ai4/data-request";
import { Modal } from "react-bootstrap";
import TenantsTabs from "./TenantsTabs";
import ErrorApiAlert from '../../../../@bootstrap/components/alert/ErrorApiAlert'

function TenantsModal(props) {
    const { requestId, requestName, showModal, setShowModal } = props;
    const handleClose = () => {
        setShowModal(false); setTimeout(() => {
            setIsDatabaseDetails(false);
        }, 300);
    };
    const [isDatabaseDetails, setIsDatabaseDetails] = useState<boolean>(false);
    const [teanantsDetails, setTeanantsDetails] = useState<Array<any>>([]);
    const [encryptionStatusDetails, setEncryptionStatusDetails] = useState<Array<any>>([]);
    const [isDatabeseRefreshed, setIsDatabeseRefreshed] = useState<boolean>(false);
    const { useRestRequest } = useDataRequest();

    const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorCode, setErrorCode] = useState<string>("");

    const [tenantsId] = useRestRequest({
        path: `api/{ver}/tenants/${requestId}`,
        method: "GET",
        jwt: true,
    });

    const tenantsIdCall = useCallback(
        async (requestId
        ) => {
            const tenantsIdPage = (await tenantsId({
                path: `api/{ver}/tenants/${requestId}`,
                method: "GET",
                jwt: true,
            })) as any;

            return tenantsIdPage;
        },
        [tenantsId]
    );

    const [encryptionStatus] = useRestRequest({
        path: `api/{ver}/tenants/${requestId}/encryption-status`,
        method: "GET",
        jwt: true,
    });

    const encryptionStatusCall = useCallback(
        async (requestId
        ) => {
            const encryptionStatusPage = (await encryptionStatus({
                path: `api/{ver}/tenants/${requestId}/encryption-status`,
                method: "GET",
                jwt: true,
            })) as any;

            return encryptionStatusPage;
        },
        [encryptionStatus]
    );

    const getTenantsInfo = (requestId) => {
        tenantsIdCall(requestId)
            .then((data) => setTeanantsDetails(data))
            .catch(
                (data: any) => {
                    setIsErrorShow(true);
                    setErrorMessage(data.messages);
                    setErrorCode(data.requestId);
                });
    };

    const getTenantsDatabaseInfo = (requestId) => {
        encryptionStatusCall(requestId)
            .then((data) => {
                setEncryptionStatusDetails(data);
                setIsDatabeseRefreshed(true);
            })
            .catch((data: any) => {
                setIsErrorShow(true);
                setErrorMessage(data.messages);
                setErrorCode(data.requestId);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsDatabeseRefreshed(false);
                }, 1000);
            })
    };

    const getTenantsDetail = (requestId) => {
        getTenantsInfo(requestId);
        getTenantsDatabaseInfo(requestId);
    };

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleClose}
                onEnter={() => getTenantsDetail(requestId)}
                className="details-modal"
                size="lg"
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "start" }}>{requestName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isDatabaseDetails ? <div className="tenants-details-id">Id: <b>{requestId}</b></div> : ""}
                    <TenantsTabs
                        requestId={requestId}
                        teanantsDetails={teanantsDetails}
                        encryptionStatusDetails={encryptionStatusDetails}
                        getTenantsInfo={getTenantsInfo}
                        getTenantsDatabaseInfo={getTenantsDatabaseInfo}
                        isDatabaseDetails={isDatabaseDetails}
                        setIsDatabaseDetails={setIsDatabaseDetails}
                        isDatabeseRefreshed={isDatabeseRefreshed}
                    />
                </Modal.Body>
            </Modal>

            <ErrorApiAlert
                errors={errorMessage}
                errorCode={errorCode}
                isErrorShow={isErrorShow}
                setIsErrorShow={setIsErrorShow}
                setErrorMessage={setErrorMessage}
                setErrorCode={setErrorCode}
            />
        </>
    );
}

export default TenantsModal;
