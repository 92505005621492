import { useState, useCallback } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest } from "@ai4/data-request";
import Table from "../../../@bootstrap/components/Table";
import LogsFilters from "./LogsFilters";
import Spinner from "../../../@bootstrap/components/Spinner";
import LogsModal from "./modal/LogsModal";
import { formatDate } from "../../../@bootstrap/features/utilities";
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'

function LogsApi() {
  const { useRestRequest } = useDataRequest();
  const [requestId, setRequestId] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);
  const [valuePerColumnApiLogs, setValuePerColumnApiLogs] = useState<Array<any>>([]);
  const [columns, setColumns] = useState<Array<any>>([
    { name: "uniqueId", toggle: false },
    { name: "requestTime", toggle: true },
    { name: "domain", toggle: false },
    { name: "node", toggle: true },
    { name: "processTime", toggle: true },
    { name: "success", toggle: true },
    { name: "user", toggle: false },
    { name: "request length", toggle: false },
    { name: "response length", toggle: false },
  ]);
  const [paginationValue] = useState<Array<any>>(["10", "25", "30", "50"]);
  const [defaultPage, setDefaultPage] = useState<string>(paginationValue[0]);
  const [paginationTotalCount, setPaginationTotalCount] = useState<string>("");
  const [paginationTotalPages, setPaginationTotalPages] = useState<string>("");
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<string>("1");
  const [tenantValue, setTenantValue] = useState<string>("");
  const [fromDateValue, setFromDateValue] = useState<string>("");
  const [toDateValue, setToDateValue] = useState<string>("");
  const [selectedNode, setSelectedNode] = useState<string>("");
  const [selectedSuccess, setSelectedSuccess] = useState<string>("");
  const [selectedRequestType, setSelectedRequestType] = useState<string>("");
  const [selectedProcessTimeFrom, setSelectedProcessTimeFrom] = useState<string>("");
  const [selectedProcessTimeTo, setSelectedProcessTimeTo] = useState<string>("");
  const [isNoResult, setIsNoResult] = useState<boolean>(false);
  const logsType: string = "logApi";

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const apiRequestValues: Array<any> = [];
  apiRequestValues.push(
    tenantValue,
    fromDateValue,
    toDateValue,
    selectedNode,
    selectedSuccess,
    selectedRequestType,
    selectedProcessTimeFrom,
    selectedProcessTimeTo
  );

  //http rest application-logs
  const [reqApiLogs] = useRestRequest({
    path: `api/{ver}/logs/api-log?TenantId=${tenantValue}&PageNumber=${paginationCurrentPage}&PageSize=${defaultPage}&Time.From=${fromDateValue}&Time.To=${toDateValue}&NodeUniqueId=${selectedNode}&Success=${selectedSuccess}&RequestType=${selectedRequestType}&ProcessTime.From=${selectedProcessTimeFrom}&ProcessTime.To=${selectedProcessTimeTo}`,
    method: "GET",
    jwt: true,
  });

  const requestApiLogs = useCallback(
    async (
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedNode,
      selectedSuccess,
      selectedRequestType,
      selectedProcessTimeFrom,
      selectedProcessTimeTo
    ) => {
      const reqApiLogsPage = (await reqApiLogs({
        path: `api/{ver}/logs/api-log?TenantId=${tenantValue}&PageNumber=${pageNumber}&PageSize=${pageSize}&Time.From=${fromDateValue}&Time.To=${toDateValue}&NodeUniqueId=${selectedNode}&Success=${selectedSuccess}&RequestType=${selectedRequestType}&ProcessTime.From=${selectedProcessTimeFrom}&ProcessTime.To=${selectedProcessTimeTo}`,
        method: "GET",
        jwt: true,
      })) as any;
      return reqApiLogsPage;
    },
    [reqApiLogs]
  );

  // Function to get response and save in state
  const callApiLogs = (
    pageSize,
    pageNumber,
    tenantValue,
    fromDateValue,
    toDateValue,
    selectedNode,
    selectedSuccess,
    selectedRequestType,
    selectedProcessTimeFrom,
    selectedProcessTimeTo
  ) => {
    let apiLogsPage;
    setIsNoResult(false);
    setSpinnerShow(true);
    requestApiLogs(
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedNode,
      selectedSuccess,
      selectedRequestType,
      selectedProcessTimeFrom,
      selectedProcessTimeTo
    )
      .then((data) => {
        apiLogsPage = data.data;
        setValuePerColumnApiLogs(apiLogsPage);
        setPaginationTotalCount(data.totalCount);
        setPaginationTotalPages(data.totalPages);
        setPaginationCurrentPage(data.currentPage);
        setSpinnerShow(false);
        if (apiLogsPage.length === 0) {
          setIsNoResult(true);
        }
        if (data.currentPage > data.totalPages) {
          callApiLogs(
            pageSize,
            data.totalPages,
            tenantValue,
            fromDateValue,
            toDateValue,
            selectedNode,
            selectedSuccess,
            selectedRequestType,
            selectedProcessTimeFrom,
            selectedProcessTimeTo);
        }
      })
      .catch((data) => {
        setIsNoResult(true);
        setSpinnerShow(false);
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
  };

  // function to show or hide columns
  const onColumnToggle = (el) => {
    setColumns(
      columns.map(function (e) {
        if (e.name === el) {
          e.toggle = !e.toggle;
        }
        return e;
      })
    );
  };

  const handleShowModal = (e) => {
    setRequestId(e.target.getAttribute("data-uniqueid"));
    setShowModal(true);
  };

  // function to set table body from api
  const returnValuesRows = () => {
    return valuePerColumnApiLogs.map((data, index) => (
      <tr key={index} className="table-modal-button">
        {columns[0].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.uniqueId}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[1].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {formatDate(data.requestTime)}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[2].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.domain}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[3].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.node}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[4].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            <div
              className="process-time"
              data-uniqueid={data.uniqueId}
              onClick={(e) => {
                handleShowModal(e);
              }}
            >
              {data.processTime}
              <span
                data-uniqueid={data.uniqueId}
                onClick={(e) => {
                  handleShowModal(e);
                }}
                className={
                  data.processTime < 100
                    ? "badge badge-grey"
                    : data.processTime < 250
                      ? "badge badge-yellow"
                      : "badge badge-red"
                }
              >
                {data.processTime < 100 ? "Good" : data.processTime < 250 ? "Med" : "Bad"}
              </span>
            </div>
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[5].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.success ? "True" : "False"}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[6].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.user}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[7].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.requestLength}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[8].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.responseLength}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
      </tr>
    ));
  };

  return (
    <>
      <LogsFilters
        logsType={logsType}
        hasApiFilters={true}
        tenantValue={tenantValue}
        setTenantValue={setTenantValue}
        fromDateValue={fromDateValue}
        setFromDateValue={setFromDateValue}
        toDateValue={toDateValue}
        setToDateValue={setToDateValue}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        selectedSuccess={selectedSuccess}
        setSelectedSuccess={setSelectedSuccess}
        selectedRequestType={selectedRequestType}
        setSelectedRequestType={setSelectedRequestType}
        selectedProcessTimeFrom={selectedProcessTimeFrom}
        setSelectedProcessTimeFrom={setSelectedProcessTimeFrom}
        selectedProcessTimeTo={selectedProcessTimeTo}
        setSelectedProcessTimeTo={setSelectedProcessTimeTo}
        callApiGet={callApiLogs}
        paginationCurrentPage={paginationCurrentPage}
        defaultPage={defaultPage}
        isNoResult={isNoResult}
        apiRequestValues={apiRequestValues}
      />

      <div className={returnValuesRows().length > 0 ? "logs-container" : "d-none"}>
        <div className="logs-container__label">Api Logs</div>
        <Table
          filters={false}
          columns={columns}
          onColumnToggle={onColumnToggle}
          returnValuesRows={returnValuesRows}
          paginationValue={paginationValue}
          defaultPage={defaultPage}
          setDefaultPage={setDefaultPage}
          callApiGet={callApiLogs}
          paginationTotalCount={paginationTotalCount}
          paginationTotalPages={paginationTotalPages}
          paginationCurrentPage={paginationCurrentPage}
          apiRequestValues={apiRequestValues}
        />
      </div>

      <LogsModal
        logsType={logsType}
        tenantValue={tenantValue}
        requestId={requestId}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />

      <Spinner show={spinnerShow} />
    </>
  );
}

export default LogsApi;
