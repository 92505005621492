import "@ai4/form-builder/dist/index.css";
import { useState, useCallback, useEffect } from "react";
import { useDataRequest } from "@ai4/data-request";
import LogsTabsTrace from "../../logs/modal/LogsTabsTrace";
import Spinner from "../../../../@bootstrap/components/Spinner";
import ErrorApiAlert from '../../../../@bootstrap/components/alert/ErrorApiAlert'

function MoreDetailDataModal(props) {
  const { requestId, setMoreDetailsData, tenantValue } = props;
  const { useRestRequest } = useDataRequest();
  const [isNoResult, setIsNoResult] = useState<boolean>(false);
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const [reqApiLog, resApiLog] = useRestRequest({
    path: `api/{ver}/logs/api-log/${requestId}?TenantId=${tenantValue}`,
    method: "GET",
    jwt: true,
  });

  const requesApiLogs = useCallback(
    async (requestId, tenantValue) => {
      const reqApiLogDetail = (await reqApiLog({
        path: `api/{ver}/logs/api-log/${requestId}?TenantId=${tenantValue}`,
        method: "GET",
        jwt: true,
      })) as any;
      return reqApiLogDetail;
    },
    [reqApiLog]
  );

  const getModalLogs = (requestId, tenantValue) => {
    setIsNoResult(false);
    setSpinnerShow(true);
    requesApiLogs(requestId, tenantValue)
      .then((data) => {
        if (Object.keys(data).length === 0) {
          setIsNoResult(true);
        }
        setSpinnerShow(false);
      })
      .catch((data) => {
        setIsNoResult(true);
        setSpinnerShow(false);
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
  };

  useEffect(() => {
    getModalLogs(requestId, tenantValue);
  }, [requestId, tenantValue]);

  const details: any = resApiLog;

  return (
    <>
      <button className="btn btn-secondary mb-4" onClick={() => setMoreDetailsData(false)}>
        back
      </button>
      {!isNoResult ? (
        <LogsTabsTrace details={details} isApiLog={true} />
      ) : (
        <div className="logs-modal__error">No resault founded...</div>
      )}

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />

      <Spinner show={spinnerShow} />
    </>
  );
}

export default MoreDetailDataModal;
