import { useEffect, useState, useCallback } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest } from "@ai4/data-request";
import DateConfirmationModal from "../dashboard/modal/DateConfirmationModal";
import { formatDate, pad } from "../../../@bootstrap/features/utilities";
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'

function LogsFilters(props) {
  const {
    logsType,
    hasApiFilters,
    tenantValue,
    setTenantValue,
    fromDateValue,
    setFromDateValue,
    toDateValue,
    setToDateValue,
    selectedNode,
    setSelectedNode,
    selectedSuccess,
    setSelectedSuccess,
    selectedRequestType,
    setSelectedRequestType,
    selectedProcessTimeFrom,
    setSelectedProcessTimeFrom,
    selectedProcessTimeTo,
    setSelectedProcessTimeTo,
    selectedUser,
    setSelectedUser,
    callApiGet,
    paginationCurrentPage,
    defaultPage,
    isNoResult,
    apiRequestValues,
  } = props;

  const date = new Date();
  const defaultMaxDateFormat =
    date.getFullYear() + "-" + pad((date.getMonth() + 1)) + "-" + pad(date.getDate());
  const defaultMinDate: Date = new Date();
  defaultMinDate.setDate(pad(date.getDate() - 7));
  const defaultMinDateFormat =
    defaultMinDate.getFullYear() +
    "-" +
    (pad(defaultMinDate.getMonth() + 1)) +
    "-" +
    pad(defaultMinDate.getDate());

  const filterVisible: boolean =
    typeof hasApiFilters === "undefined" || hasApiFilters === null || hasApiFilters;
  const { useRestRequest } = useDataRequest();
  const [accordion, toggleAccordion] = useState<boolean>(true);
  const [nodes, setNodes] = useState<Array<any>>([]);
  const [users, setUsers] = useState<Array<string>>([]);
  const [prevFromDateValue, setPrevFromDateValue] = useState<string>(defaultMinDateFormat);
  const [prevToDateValue, setPrevToDateValue] = useState<string>(defaultMaxDateFormat);
  const [showDateAlertModal, setShowDateAlertModal] = useState<boolean>(false);
  const [isErrorFormatDate, setIsErrorFormatDate] = useState<boolean>(false);

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const onDateBlur = () => {
    const dateFrom = new Date(fromDateValue);
    const dateTo = new Date(toDateValue);

    if (
      (dateFrom.getDate() > dateTo.getDate() &&
        dateFrom.getMonth() === dateTo.getMonth() &&
        dateFrom.getFullYear() === dateTo.getFullYear()) ||
      (dateFrom.getMonth() > dateTo.getMonth() &&
        dateFrom.getFullYear() === dateTo.getFullYear()) ||
      dateFrom.getFullYear() > dateTo.getFullYear()
    ) {
      setIsErrorFormatDate(true);
      setFromDateValue(prevFromDateValue);
      setToDateValue(prevToDateValue);
      setTimeout(() => {
        setIsErrorFormatDate(false);
      }, 5000);
    }
  };

  const applyFilters = () => {
    const dateFrom = new Date(fromDateValue);
    const dateTo = new Date(toDateValue);
    if (
      (dateTo.getDate() - dateFrom.getDate() > 7 ||
        dateTo.getMonth() !== dateFrom.getMonth() ||
        dateTo.getFullYear() !== dateFrom.getFullYear())
    ) {
      setShowDateAlertModal(true);
      setIsErrorFormatDate(false);
    } else {
      applyFilterCall();
    }
  }

  const applyFilterCall = () => {
    callApiGet(
      defaultPage,
      paginationCurrentPage,
      apiRequestValues[0],
      apiRequestValues[1],
      apiRequestValues[2],
      apiRequestValues[3],
      apiRequestValues[4],
      apiRequestValues[5],
      apiRequestValues[6],
      apiRequestValues[7]
    );
  }

  const [reqTenants, resTenants] = useRestRequest({
    path: "api/{ver}/tenants",
    method: "GET",
    jwt: true,
  });

  const [reqUsers] = useRestRequest({
    path: `api/{ver}/users?TenantId=${tenantValue}`,
    method: "GET",
    jwt: true,
  });

  const [reqLogsNodes] = useRestRequest({
    path: `api/{ver}/logs/api-log-nodes?TenantId=${tenantValue}&PageSize=${defaultPage}&PageNumber=${paginationCurrentPage}`,
    method: "GET",
    jwt: true,
  });

  const requestLogsUsers = useCallback(
    async (tenantValue) => {
      const reqLogUsers = (await reqUsers({
        path: `api/{ver}/users?TenantId=${tenantValue}`,
        method: "GET",
        jwt: true,
      })) as Array<any>;
      return reqLogUsers;
    },
    [reqUsers]
  );

  const requestLogsNodes = useCallback(
    async (tenantValue, paginationCurrentPage) => {
      const reqAllLogsNodes = (await reqLogsNodes({
        path: `api/{ver}/logs/api-log-nodes?TenantId=${tenantValue}&PageSize=200&PageNumber=${paginationCurrentPage}`,
        method: "GET",
        jwt: true,
      })) as Array<any>;
      return reqAllLogsNodes;
    },
    [reqLogsNodes]
  );

  useEffect(() => {
    reqTenants();
  }, [reqTenants]);

  useEffect(() => {
    setFromDateValue(defaultMinDateFormat);
    setToDateValue(defaultMaxDateFormat);
  }, []);

  useEffect(() => {
    if (logsType === "logApi") {
      if (tenantValue !== "" && typeof (tenantValue) !== "undefined") {
        requestLogsNodes(tenantValue, paginationCurrentPage)
          .then((data: any) => {
            setNodes(data.data);
          })
          .catch((data) => {
            setNodes([]);
            setIsErrorShow(true);
            setErrorMessage(data.messages);
            setErrorCode(data.requestId);
          });
      }
    }
    if (logsType === "apiRuntimeLogs") {
      if (tenantValue !== "" && typeof (tenantValue) !== "undefined") {
        requestLogsUsers(tenantValue)
          .then((data) => {
            const usersArray: Array<string> = [];
            data.map((value) => usersArray.push(value.userName));
            setUsers(usersArray);
          })
          .catch((data) => {
            setUsers([]);
            setIsErrorShow(true);
            setErrorMessage(data.messages);
            setErrorCode(data.requestId);
          });
      }
    }
  }, [
    tenantValue,
    defaultPage,
    logsType,
    paginationCurrentPage,
    requestLogsNodes,
    requestLogsUsers,
  ]);

  const tenants = resTenants.data;

  const requestType = [
    "GRAPHQL_QUERY",
    "GRAPHQL_MUTATION",
    "GRAPHQL_UNKNOWN",
    "POST",
    "GET",
    "PUT",
    "DELETE",
    "PATCH",
  ];

  return (
    <>
      {/* api Filters */}
      <div className={filterVisible ? "" : "d-none"}>
        <div
          className={accordion ? "api-filter-container mb-5" : "api-filter-container mb-5 closed"}
        >
          <div className="filter-label">Filters</div>
          <div className="row">
            <div className="col-12">
              <button
                className={accordion ? "btn accordion-button" : "btn accordion-button closed"}
                onClick={() => toggleAccordion(!accordion)}
              >
                <div className="container-filters-values">
                  {apiRequestValues.map((data, index) => (data !== "" ? <div key={index}>{index === 1 || index === 2 ? formatDate(data).split(" ")[0] : data},</div> : ""))}
                </div>
                <i className="i-Arrow-Down"></i>
              </button>
            </div>
          </div>
          <div className="accordion-content">
            <div className="row align-items-center">
              <div className="col-12 col-md-2">Tenant</div>
              <div className="col-12 col-md-10">
                <select
                  name="tenants"
                  id="tenantsId"
                  onChange={(e) => setTenantValue(e.target.value)}
                >
                  <option value="">--Select a tenant--</option>
                  {typeof tenants !== "undefined" && tenants !== null
                    ? tenants.map((tenant, index) => {
                      return <option key={index} value={tenant.id}>{tenant.name}</option>;
                    })
                    : ""}
                </select>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-12 col-md-6 mt-3">
                <div className="row align-items-center">
                  <div className="col-12 col-md-4">
                    <label htmlFor="">From</label>
                  </div>
                  <div className="col-12 col-md-8">
                    <input
                      type="date"
                      id=""
                      className=""
                      name=""
                      value={fromDateValue}
                      max={toDateValue}
                      onChange={(e) => setFromDateValue(e.target.value)}
                      onFocus={() => setPrevFromDateValue(fromDateValue)}
                      onBlur={() => onDateBlur()}
                      disabled={tenantValue === ""}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-3">
                <div className="row align-items-center">
                  <div className="col-12 col-md-4">
                    <label htmlFor="">To</label>
                  </div>
                  <div className="col-12 col-md-8">
                    <input
                      type="date"
                      id=""
                      className=""
                      name=""
                      value={toDateValue}
                      min={fromDateValue}
                      onChange={(e) => setToDateValue(e.target.value)}
                      onFocus={() => setPrevToDateValue(toDateValue)}
                      onBlur={() => onDateBlur()}
                      disabled={tenantValue === ""}
                    />
                  </div>
                </div>
              </div>
              {isErrorFormatDate ? (
                <div className="format-date-error col-12 offset-md-2 col-md-8 mt-2">
                  Uncorrect date selection. <b>From Date</b> can't be bigger of than <b>To Date</b>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* logApi */}
            {logsType === "logApi" ? (
              <div className="row align-items-center">
                <div className="col-12 col-md-6 mt-3">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <label htmlFor="">Node</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <select
                        name="node"
                        id="nodeId"
                        value={selectedNode}
                        onChange={(e) => setSelectedNode(e.target.value)}
                        disabled={tenantValue === ""}
                      >
                        <option value="">--Select a node--</option>
                        {nodes.length > 0
                          ? nodes.map((value, index) => {
                            return <option key={index} value={value.uniqueId}>{value.node}</option>;
                          })
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <label htmlFor="">succes</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <select
                        name="success"
                        id="successId"
                        value={selectedSuccess}
                        onChange={(e) => setSelectedSuccess(e.target.value)}
                        disabled={tenantValue === ""}
                      >
                        <option value="">--Select a success--</option>
                        <option value="true">yes</option>
                        <option value="false">no</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* logApi */}
            {logsType === "logApi" ? (
              <div className="row align-items-center mt-3">
                <div className="col-12 col-md-2">Request type</div>
                <div className="col-12 col-md-10">
                  <select
                    name="requestType"
                    id="requestTypeId"
                    value={selectedRequestType}
                    onChange={(e) => setSelectedRequestType(e.target.value)}
                    disabled={tenantValue === ""}
                  >
                    <option value="">--Select a requestType--</option>
                    {requestType.map((value, index) => {
                      return <option value={value} key={index}>{value}</option>;
                    })}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* api runtime Logs, logApi */}
            {logsType === "apiRuntimeLogs" || logsType === "logApi" ? (
              <div className="row align-items-center">
                <div className="col-12 col-md-6 mt-3">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <label htmlFor="">Process time from</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <input
                        type="number"
                        id="processTimeFromId"
                        name="processTimeFrom"
                        value={selectedProcessTimeFrom}
                        onChange={(e) => {
                          setSelectedProcessTimeFrom(e.target.value);
                        }}
                        disabled={tenantValue === ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <label htmlFor="">To</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <input
                        type="number"
                        id="processTimeToId"
                        name="processTimeTo"
                        value={selectedProcessTimeTo}
                        onChange={(e) => {
                          setSelectedProcessTimeTo(e.target.value);
                        }}
                        disabled={tenantValue === ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* api runtime Logs */}
            {logsType === "apiRuntimeLogs" ? (
              <div className="row align-items-center mt-3">
                <div className="col-12 col-md-2">User</div>
                <div className="col-12 col-md-10">
                  <select
                    name="user"
                    id="userId"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    disabled={tenantValue === ""}
                  >
                    <option value="">--Select a user--</option>
                    {users.length > 0
                      ? users.map((value, index) => <option value={value} key={index}>{value}</option>)
                      : ""}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}

            {isNoResult ? <div className="no-result mt-4">No result found</div> : ""}

            <div className="row align-items-center mt-3">
              <div className="col-12">
                <div className="button-container">
                  <button
                    className="btn btn-secondary"
                    onClick={() => { applyFilters() }}
                    disabled={tenantValue === ""}
                  >
                    Apply filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DateConfirmationModal
        showDateAlertModal={showDateAlertModal}
        setShowDateAlertModal={setShowDateAlertModal}
        prevFromDateValue={prevFromDateValue}
        prevToDateValue={prevToDateValue}
        setToDateValue={setToDateValue}
        setFromDateValue={setFromDateValue}
        applyFilterCall={applyFilterCall}
      />

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />
    </>
  );
}

export default LogsFilters;
