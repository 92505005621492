import { authRoles } from "src/app/roles";
import ApplicationLogs from "./ApplicationLogs";
import LogsRuntime from "./LogsRuntime";
import LogsApi from "./LogsApi";
import LogsTrace from "./LogsTrace";

const logsRoutes = [
  {
    path: "logs/logTrace",
    element: <LogsTrace />,
    auth: authRoles.admin,
  },
  {
    path: "logs/runtimeLogApi",
    element: <LogsRuntime />,
    auth: authRoles.admin,
  },
  {
    path: "logs/logApi",
    element: <LogsApi />,
    auth: authRoles.admin,
  },
  {
    path: "logs/application",
    element: <ApplicationLogs />,
    auth: authRoles.admin,
  },
];

export default logsRoutes;
