import { useEffect, useState, useCallback } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest } from "@ai4/data-request";
import Table from "../../../@bootstrap/components/Table";
import LogsFilters from "./LogsFilters";
import Spinner from "../../../@bootstrap/components/Spinner";
import { formatDate } from "../../../@bootstrap/features/utilities";
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'

function ApplicationLogs(props) {
  const {
    logTrace,
    logTraceApi,
    hasApiFilters,
    tenantValueTrace,
    applicationLogsTrace,
    paginationTotalCountTrace,
    paginationTotalPagesTrace,
    paginationCurrentPageTrace,
    isTraceFilterActive,
    isTraceDisabledButton,
  } = props;
  const isLogTrace = logTrace ? true : false;
  const { useRestRequest } = useDataRequest();
  const [isApplicationFilterActive, setIsApplicationFilterActive] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [applicationLogs, setApplicationLogs] = useState<Array<any>>([]);
  const [filtredApplicationLogs, setFiltredApplicationLogs] = useState<Array<any>>([]);
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<any>>([
    { name: "Id", toggle: true },
    { name: "DateTime", toggle: true },
    { name: "Level", toggle: true },
    { name: "Username", toggle: false },
    { name: "Message", toggle: true },
    { name: "Category", toggle: true },
    { name: "Properties", toggle: false },
    { name: "Exception", toggle: false },
    { name: "RequestId", toggle: false },
  ]);
  const filters: Array<string> = ["Information", "Warning", "Error", "Fatal", "All"];
  const [paginationValue] = useState<Array<string>>(["10", "25", "30", "50"]);
  const [defaultPage, setDefaultPage] = useState<string>(paginationValue[0]);
  const [paginationTotalCount, setPaginationTotalCount] = useState<string>("");
  const [paginationTotalPages, setPaginationTotalPages] = useState<string>("");
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<string>("1");
  const [tenantValue, setTenantValue] = useState<string>("");
  const [fromDateValue, setFromDateValue] = useState<string>("");
  const [toDateValue, setToDateValue] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [isNoResult, setIsNoResult] = useState<boolean>(false);
  const logsType = "applicationLogs";

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const apiRequestValues: Array<string> = [];
  apiRequestValues.push(tenantValue, fromDateValue, toDateValue, selectedCategory);

  //http rest application-logs
  const [reqApplicationLogs] = useRestRequest({
    path: `api/{ver}/logs/application-logs?TenantId=${tenantValue}&PageNumber=${paginationCurrentPage}&PageSize=${defaultPage}&TimeStamp.From=${fromDateValue}&TimeStamp.To=${toDateValue}&Category=${selectedCategory}`,
    method: "GET",
    jwt: true,
  });

  useEffect(() => {
    if (
      isLogTrace &&
      typeof applicationLogsTrace !== "undefined" &&
      applicationLogsTrace !== null
    ) {
      setApplicationLogs(applicationLogsTrace);
      setFiltredApplicationLogs(applicationLogsTrace);
      setPaginationTotalCount(paginationTotalCountTrace);
      setPaginationTotalPages(paginationTotalPagesTrace);
      setPaginationCurrentPage(paginationCurrentPageTrace);
      setTenantValue(tenantValueTrace);
    }
  }, [
    logTraceApi,
    applicationLogsTrace,
    isLogTrace,
    paginationCurrentPageTrace,
    paginationTotalPagesTrace,
    paginationTotalCountTrace,
    tenantValueTrace,
  ]);

  const requestApplicationLogs = useCallback(
    async (
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedCategory
    ) => {
      const reqApplicationLogsPage = (await reqApplicationLogs({
        path: `api/{ver}/logs/application-logs?TenantId=${tenantValue}&PageNumber=${pageNumber}&PageSize=${pageSize}&TimeStamp.From=${fromDateValue}&TimeStamp.To=${toDateValue}&Category=${selectedCategory}`,
        method: "GET",
        jwt: true,
      })) as any;
      return reqApplicationLogsPage;
    },
    [reqApplicationLogs]
  );

  // Function to get response and save in state
  const callApplicationLogs = (
    pageSize,
    pageNumber,
    tenantValue,
    fromDateValue,
    toDateValue,
    selectedCategory
  ) => {
    setIsNoResult(false);
    setSpinnerShow(true);
    let applicationLogsPage;
    requestApplicationLogs(
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedCategory
    )
      .then((data) => {
        applicationLogsPage = data.data;
        setIsApplicationFilterActive(true);
        setPaginationTotalCount(data.totalCount);
        setPaginationTotalPages(data.totalPages);
        setPaginationCurrentPage(data.currentPage);
        setApplicationLogs(applicationLogsPage);
        setFiltredApplicationLogs(applicationLogsPage);
        setSpinnerShow(false);
        setIsApplicationFilterActive(false);
        setIsDisabledButton(false);
        if (applicationLogsPage.length === 0) {
          setIsNoResult(true);
          setIsApplicationFilterActive(false);
          setIsDisabledButton(true);
        }
        if (data.currentPage > data.totalPages) {
          callApplicationLogs(
            pageSize,
            data.totalPages,
            tenantValue,
            fromDateValue,
            toDateValue,
            selectedCategory
          )
        }
      })
      .catch((data) => {
        setIsNoResult(true);
        setSpinnerShow(false);
        setIsApplicationFilterActive(false);
        setIsDisabledButton(true);
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
  };

  // Function to filter with level
  const setFilter = (e) => {
    if (e.target.value !== "All") {
      let applicationLogsTemp = applicationLogs.filter(function (el) {
        return el.level === e.target.value;
      });
      setFiltredApplicationLogs(applicationLogsTemp);
    } else {
      setFiltredApplicationLogs(applicationLogs);
    }
  };

  // function to show or hide columns
  const onColumnToggle = (el) => {
    setColumns(
      columns.map(function (e) {
        if (e.name === el) {
          e.toggle = !e.toggle;
        }
        return e;
      })
    );
  };

  // function to set table body from api
  const returnValuesRows = () => {
    return filtredApplicationLogs.map((data, index) => (
      <tr key={index}>{columns[0].toggle ? <td>{data.id}</td> : <td style={{ display: "none" }}></td>}
        {columns[1].toggle ? <td>{formatDate(data.timeStamp)}</td> : <td style={{ display: "none" }}></td>}
        {columns[2].toggle ? <td>{data.level}</td> : <td style={{ display: "none" }}></td>}
        {columns[3].toggle ? <td>{data.username}</td> : <td style={{ display: "none" }}></td>}
        {columns[4].toggle ? <td>{data.message}</td> : <td style={{ display: "none" }}></td>}
        {columns[5].toggle ? <td>{data.category}</td> : <td style={{ display: "none" }}></td>}
        {columns[6].toggle ? <td>{data.properties}</td> : <td style={{ display: "none" }}></td>}
        {columns[7].toggle ? <td>{data.exception}</td> : <td style={{ display: "none" }}></td>}
        {columns[8].toggle ? <td>{data.requestId}</td> : <td style={{ display: "none" }}></td>}</tr>
    ));
  };

  return (
    <>
      {/* api Filters */}
      <LogsFilters
        logsType={logsType}
        hasApiFilters={hasApiFilters}
        tenantValue={tenantValue}
        setTenantValue={setTenantValue}
        fromDateValue={fromDateValue}
        setFromDateValue={setFromDateValue}
        toDateValue={toDateValue}
        setToDateValue={setToDateValue}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        callApiGet={callApplicationLogs}
        paginationCurrentPage={paginationCurrentPage}
        defaultPage={defaultPage}
        isNoResult={isNoResult}
        apiRequestValues={apiRequestValues}
      />

      <div className={true ? "logs-container" : "d-none"}>
        <div className="logs-container__label">Application Logs</div>
        <Table
          isLogTrace={isLogTrace}
          filters={filters}
          setFilter={setFilter}
          columns={columns}
          onColumnToggle={onColumnToggle}
          returnValuesRows={returnValuesRows}
          paginationValue={paginationValue}
          defaultPage={defaultPage}
          setDefaultPage={setDefaultPage}
          callApiGet={callApplicationLogs}
          paginationTotalCount={paginationTotalCount}
          paginationTotalPages={paginationTotalPages}
          paginationCurrentPage={paginationCurrentPage}
          apiRequestValues={apiRequestValues}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={filtredApplicationLogs}
          isApplicationFilterActive={
            typeof isTraceFilterActive !== "undefined"
              ? isTraceFilterActive
              : isApplicationFilterActive
          }
          isDisabledButton={
            typeof isTraceDisabledButton !== "undefined" ? isTraceDisabledButton : isDisabledButton
          }
        />
      </div>

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />

      <Spinner show={spinnerShow} />
    </>
  );
}

export default ApplicationLogs;
