import { useState, useEffect, useCallback, useRef } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest } from '@ai4/data-request';
import DatabaseMoreDetails from './DatabaseMoreDetails'
import ErrorApiAlert from '../../../../@bootstrap/components/alert/ErrorApiAlert'
import Spinner from "../../../../@bootstrap/components/Spinner";


function TenantsTabs(props) {
    const {
        requestId,
        teanantsDetails,
        encryptionStatusDetails,
        getTenantsInfo,
        getTenantsDatabaseInfo,
        isDatabaseDetails,
        setIsDatabaseDetails,
        isDatabeseRefreshed
    } = props;
    const [isTenantInfoActive, setIsTenantInfoActive] = useState<boolean>(true);
    const [prevValidUpto, setPrevValidUpto] = useState<string>("");
    const [validUpto, setValidUpto] = useState<string>("");
    const [isValidUpToUpdated, setIsValidUpToUpdated] = useState<boolean>(false);
    const { useRestRequest } = useDataRequest();
    const copyToClipboard = useRef<HTMLInputElement>(null);

    const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorCode, setErrorCode] = useState<string>("");

    const [execTenantsUpgrade, resTenantsUpgrade] = useRestRequest({
        path: 'api/{ver}/tenants/{id}/upgrade',
        method: 'POST',
        jwt: true,
    });

    const [execTenantsEncryption, resTenantsEncryption] = useRestRequest({
        path: 'api/{ver}/tenants/{id}/encryption',
        method: 'POST',
        jwt: true,
    });

    const [execActivateTenant, resActivateTenant] = useRestRequest({
        path: 'api/{ver}/tenants/{id}/activate',
        method: 'POST',
        jwt: true,
    });

    const [execDeactivateTenant, resDeactivateTenant] = useRestRequest({
        path: 'api/{ver}/tenants/{id}/deactivate',
        method: 'POST',
        jwt: true,
    });

    const upgradeValidUpTo = useCallback(async (requestId, validUpto) => {
        await execTenantsUpgrade({
            path: `api/{ver}/tenants/${requestId}/upgrade`,
            data: {
                tenantId: requestId,
                extendedExpiryDate: validUpto
            },
        }).then(() => {
            setPrevValidUpto(validUpto);
            setIsValidUpToUpdated(true);
            setTimeout(() => {
                setIsValidUpToUpdated(false);
            }, 3000);
        }).catch(
            (data: any) => {
                setIsErrorShow(true);
                setErrorMessage(data.messages);
                setErrorCode(data.requestId);
            });
    }, [execTenantsUpgrade]);

    const tenantEncryption = useCallback(async (requestId, isEncryption) => {
        await execTenantsEncryption({
            path: `api/{ver}/tenants/${requestId}/encryption`,
            data: {
                id: requestId,
                enabled: isEncryption
            },
        }).catch(
            (data: any) => {
                setIsErrorShow(true);
                setErrorMessage(data.messages);
                setErrorCode(data.requestId);
            });
        getTenantsDatabaseInfo(requestId);
    }, [execTenantsEncryption]);

    const toggleActivationTenant = useCallback(async (requestId, isActivation) => {
        if (isActivation) {
            await execActivateTenant({
                path: `api/{ver}/tenants/${requestId}/activate`
            }).catch(
                (data: any) => {
                    setIsErrorShow(true);
                    setErrorMessage(data.messages);
                    setErrorCode(data.requestId);
                });
        }
        else {
            await execDeactivateTenant({
                path: `api/{ver}/tenants/${requestId}/deactivate`
            }).catch(
                (data: any) => {
                    setIsErrorShow(true);
                    setErrorMessage(data.messages);
                    setErrorCode(data.requestId);
                });
        }
        getTenantsInfo(requestId);
    }, [execActivateTenant, execDeactivateTenant]);

    useEffect(() => {
        if (typeof teanantsDetails.validUpto !== "undefined") {
            setPrevValidUpto(teanantsDetails.validUpto.split("T")[0]);
            setValidUpto(teanantsDetails.validUpto.split("T")[0]);
        }
    }, [teanantsDetails]);

    return (
        <>
            {isDatabaseDetails ?
                <DatabaseMoreDetails requestId={requestId} setIsDatabaseDetails={setIsDatabaseDetails} />
                :
                (
                    <div className="row">
                        <div className="col-12">
                            {/* tabs button */}
                            <div className="tenants-details-tab-container">
                                <div className="tabs-button">
                                    <button
                                        className={isTenantInfoActive ? "btn active" : "btn"}
                                        onClick={() => { setIsTenantInfoActive(true); setValidUpto(prevValidUpto); }}
                                    >
                                        Tenants info
                                    </button>
                                    <button
                                        className={isTenantInfoActive ? "btn" : "btn active"}
                                        onClick={() => setIsTenantInfoActive(false)}
                                    >
                                        Database
                                    </button>
                                </div>

                                {/* Request tab */}
                                <div
                                    className={
                                        isTenantInfoActive
                                            ? "tenants-details-tab-request"
                                            : "tenants-details-tab-request d-none"
                                    }
                                >
                                    <div className="mb-2"><b>Admin Email: </b>{teanantsDetails.adminEmail}</div>
                                    <div className="mb-4">
                                        <b>Application url: </b>
                                        <input type="text" className="copy-to-clipboard" ref={copyToClipboard} defaultValue={teanantsDetails.applicationUrl}
                                            style={{ minWidth: "185px" }} readOnly />
                                        <button className="btn btn-secondary copy-clipboard-button"
                                            onClick={() => {
                                                navigator.clipboard.writeText(teanantsDetails.applicationUrl);
                                                copyToClipboard.current?.focus();
                                            }}>
                                            <i className="nav-icon i-File-Copy"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <div className="col-8 activaction-status-container">
                                            <div className="activaction-status-container__label">Activaction status</div>
                                            <div className="activation-button">
                                                <button className="btn btn-secondary" onClick={() => toggleActivationTenant(requestId, true)} disabled={teanantsDetails.isActive}>Active</button>
                                                <button className="btn btn-secondary" onClick={() => toggleActivationTenant(requestId, false)} disabled={!teanantsDetails.isActive}>Deactive</button>
                                            </div>
                                            {teanantsDetails.isActive ?
                                                <div className="validUpto-container mt-4">
                                                    <span><b>validUpto:</b><input type="date" value={validUpto} onChange={e => setValidUpto(e.target.value)} /></span>
                                                    <div className="validUpto-container__upgrade-validUpTo">
                                                        <button disabled={isValidUpToUpdated} className="btn btn-secondary" onClick={() => upgradeValidUpTo(requestId, validUpto)}>Upgrade</button>
                                                        {isValidUpToUpdated ? <div className="mt-2">Upgraded</div> : ""}
                                                    </div>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* Response tab */}
                                <div
                                    className={
                                        isTenantInfoActive
                                            ? "tenants-details-tab-response d-none"
                                            : "tenants-details-tab-response"
                                    }
                                >
                                    <div className="mb-2"><b>Connection string:</b></div>
                                    <div className="mb-3">{teanantsDetails.connectionString}</div>
                                    <button className="btn btn-secondary mb-4" onClick={() => setIsDatabaseDetails(true)}>More info...</button>

                                    <div className="col-8 encryption-status-container">
                                        <div className="encryption-status-container__label">Encryption Status</div>
                                        {/* uncomment next 3 comments to unlock logic of diasbled and enabled button if api returns differents status code  */}
                                        {/*encryptionStatusDetails.statusCode === 2 || encryptionStatusDetails.statusCode === 5*/ false ?
                                            "" :
                                            <div className="activation-button mb-4">
                                                <button className="btn btn-secondary" onClick={() => tenantEncryption(requestId, true)} /*disabled={/*encryptionStatusDetails.statusCode > 2}*/>Encrypt</button>
                                                <button className="btn btn-secondary" onClick={() => tenantEncryption(requestId, false)} /*disabled={/*encryptionStatusDetails.statusCode < 2}*/>Decrypt</button>
                                            </div>
                                        }

                                        <div>
                                            <b>Status code: </b><span>{encryptionStatusDetails.statusCode}</span>
                                            <button className={`btn btn-secondary refresh-button ${isDatabeseRefreshed ? "refreshing" : ""}`}
                                                onClick={() => getTenantsDatabaseInfo(requestId)}>
                                                {isDatabeseRefreshed ? <Spinner show={true} /> : <i className="nav-icon i-Sync"></i>}
                                            </button>
                                        </div>
                                        <div className="mb-1"><b>Status description: </b>{encryptionStatusDetails.statusDescription}</div>
                                        <div className="mb-1"><b>Backup code: </b>{encryptionStatusDetails.encryptionBackupCode}</div>
                                        <div className="mb-1"><b>Backup description: </b>{encryptionStatusDetails.encryptionBackupDescription}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            <ErrorApiAlert
                errors={errorMessage}
                errorCode={errorCode}
                isErrorShow={isErrorShow}
                setIsErrorShow={setIsErrorShow}
                setErrorMessage={setErrorMessage}
                setErrorCode={setErrorCode}
            />
        </>
    );
}

export default TenantsTabs;
