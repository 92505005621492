import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataProvider, RequestErrors } from '@ai4/data-request';
import { Props as DataProviderProps } from '@ai4/data-request/dist/components/DataContext';
import { loggedIn, loggedOut } from 'src/@bootstrap/features/auth';
import ErrorCatcher from 'src/@bootstrap/components/alert/ErrorCatcher';

type Props = Partial<DataProviderProps>;

function Data(props: Props) {
    const [boot, setBoot] = useState<boolean>();
    const [errors, setErrors] = useState<RequestErrors>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const APIBase = useMemo(() => ({
        onContinueSession: (tokens) => {
            setBoot(true);
            if (tokens) {
                dispatch(loggedIn({
                    role: 'SA'
                }));
            }
        },
        onInvalidAccess: () => {
            dispatch(loggedOut());
            /* @deprecated
            navigate("/session/signin", {
                state: { redirectUrl: location.pathname }
            });
            */
        },
        onErrors: (errors: RequestErrors) => {
			setErrors(errors.map(e => ({ ...e, messages: e.statusCode === 403 ? ['Operazione non autorizzata'] : e.messages })));
        },
        ...props,
    }), []);

    return (
        <DataProvider {...APIBase as DataProviderProps}>
            <Fragment>
            {!!errors && <ErrorCatcher
				errors={(errors || []).map(e => ({
					...e,
					message: (e.messages || []).join('\r\n')
				}))}
                hideMessages={!errors.find(e => `${e.statusCode}`.substring(0, 1) === '4')}
				onClose={() => {
					setErrors(undefined);
				}}
			/>}
            {boot && props.children as any}
            </Fragment>
        </DataProvider>
    );
}

export default Data;
