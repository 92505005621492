import "@ai4/form-builder/dist/index.css";
import { useState, useCallback, useEffect } from "react";
import { useDataRequest } from "@ai4/data-request";
import ErrorApiAlert from '../../../../@bootstrap/components/alert/ErrorApiAlert'

function DatabaseMoreDetails(props) {
    const { requestId, setIsDatabaseDetails } = props;
    const [databaseInfo, setDatabaseInfo] = useState<any>([]);
    const { useRestRequest } = useDataRequest();

    const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorCode, setErrorCode] = useState<string>("");

    const [reqDatabaseInfo, resDatabaseInfo] = useRestRequest({
        path: 'api/{ver}/tenants/{id}/database-info',
        method: 'GET',
        jwt: true,
    });

    const requestDatabaseInfo = useCallback(async (requestId) => {
        const reqDatabaseInfoPage = (await reqDatabaseInfo({
            path: `api/{ver}/tenants/${requestId}/database-info`,
            method: "GET",
            jwt: true,
        })) as any;

        return reqDatabaseInfoPage;
    },
        [reqDatabaseInfo]
    );

    const callDatabaseInfoApi = (requestId) => {
        requestDatabaseInfo(requestId)
            .then((data) => setDatabaseInfo(data))
            .catch(
                (data: any) => {
                    setIsErrorShow(true);
                    setErrorMessage(data.messages);
                    setErrorCode(data.statusCode);
                });
    }

    useEffect(() => {
        callDatabaseInfoApi(requestId)
    }, []);

    return (
        <>
            <button className="btn btn-secondary mb-4" onClick={() => setIsDatabaseDetails(false)}>back</button>
            <div className="mb-2"><b>buildClrVersion: </b>{databaseInfo.buildClrVersion}</div>
            <div className="mb-2"><b>edition: </b>{databaseInfo.edition}</div>
            <div className="mb-2"><b>engineEdition: </b>{databaseInfo.engineEdition}</div>
            <div className="mb-2"><b>productBuild: </b>{databaseInfo.productBuild}</div>
            <div className="mb-2"><b>productBuildType: </b>{databaseInfo.productBuildType}</div>
            <div className="mb-2"><b>productLevel: </b>{databaseInfo.productLevel}</div>
            <div className="mb-2"><b>productMajorVersion: </b>{databaseInfo.productMajorVersion}</div>
            <div className="mb-2"><b>productMinorVersion: </b>{databaseInfo.productMinorVersion}</div>
            <div className="mb-2"><b>productName: </b>{databaseInfo.productName}</div>
            <div className="mb-2"><b>productUpdateReference: </b>{databaseInfo.productUpdateReference}</div>
            <div className="mb-2"><b>productVersion: </b>{databaseInfo.productVersion}</div>

            <ErrorApiAlert
                errors={errorMessage}
                errorCode={errorCode}
                isErrorShow={isErrorShow}
                setIsErrorShow={setIsErrorShow}
                setErrorMessage={setErrorMessage}
                setErrorCode={setErrorCode}
            />
        </>
    );
}

export default DatabaseMoreDetails;
