import "@ai4/form-builder/dist/index.css";

function DashboardCard(props) {
  const { icon, text, value } = props;
  return (
    <>
      <div className="col-12 col-md-4 mb-5">
        <div className="card card-icon-bg card-icon-bg-primary dashboard-card o-hidden mb-4">
          <div className="card-body text-center">
            <i className={icon}></i>
            <div className="content d-flex align-items-center">
              <p className="lead text-primary text-24 mb-2 text-capitalize">{text}</p>
              <p className="text-muted mt-2 mb-0 text-capitalize">{value}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardCard;
