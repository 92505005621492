import { useState } from "react";
import "@ai4/form-builder/dist/index.css";

function TenantsFilters(props) {
    const {
        callTenants,
        name,
        setName,
        active,
        setActive
    } = props;
    const [accordion, toggleAccordion] = useState<boolean>(true);

    return (
        <>
            {/* api Filters */}
            <div
                className={accordion ? "api-filter-container mb-5" : "api-filter-container mb-5 closed"}
            >
                <div className="filter-label">Filters</div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className={accordion ? "btn accordion-button" : "btn accordion-button closed"}
                            onClick={() => toggleAccordion(!accordion)}
                        >
                            <div className="container-filters-values">
                                {
                                    name !== "" ?
                                        <span className="m-2"><b>name: </b>{name + ","}</span>
                                        : ""
                                }
                                {
                                    active !== "" ?
                                        <span className="m-2">
                                            <b>active: </b>
                                            {active === "true" ? "yes" : active === "false" ? "no" : ""}
                                        </span>
                                        : ""
                                }
                            </div>
                            <i className="i-Arrow-Down"></i>
                        </button>
                    </div>
                </div>
                <div className="accordion-content">
                    <div className="row align-items-center mb-3">
                        <div className="col-12 col-md-1">
                            <label htmlFor="name">Name:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center mb-3">
                        <div className="col-12 col-md-1">
                            <label htmlFor="active">Active:</label>
                        </div>
                        <div className="col-12 col-md-3">
                            <select
                                name="active"
                                id="active"
                                value={active}
                                onChange={(e) => setActive(e.target.value)}
                            >
                                <option value="">---</option>
                                <option value="true">yes</option>
                                <option value="false">no</option>
                            </select>
                        </div>
                    </div>
                    <button
                        onClick={() => callTenants()}
                        style={{ float: "right" }}
                        className="btn btn-secondary"
                    >
                        Apply filters
                    </button>
                </div>
            </div>
        </>
    );
}

export default TenantsFilters;
