import "@ai4/form-builder/dist/index.css";
import DashboardCard from "./DashboardCard";
import DashboardForm from "./DashboardForm";
import LinearGraph from "./LinearGraph";
import CakeGraph from "./CakeGraph";
import { useEffect, useState, useCallback } from "react";
import { useDataRequest } from "@ai4/data-request";
import FullDataModal from "./modal/FullDataModal";
import { NumericFormat } from "react-number-format";
import TenantsModal from "../tenants/modal/TenantsModal";
import { pad } from "../../../@bootstrap/features/utilities";
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'

interface rootInfoModel {
  tenantsCount: any;
  expiringTenantsCount: any;
  usersCount: any;
}

interface usageModel {
  [x: string]: any;
  [index: number]: {
    day: string;
    requestsCount: number;
    bandwidthCount: number;
    totalProcessTime: number;
    averageProcessTime: number;
    errorsCount: number;
    storageLength: number;
  };
}

interface errorsByNodeModel {
  [x: string]: any;
  [index: number]: {
    errorsCount: number;
    node: string;
    nodeUniqueId: string;
    requestsCount: number;
  };
}

interface performanceByNodeModel {
  [x: string]: any;
  [index: number]: {
    averageProcessTime: number;
    node: string;
    nodeUniqueId: string;
    requestsCount: number;
  };
}

interface tenantsModel {
  [index: number]: {
    adminEmail: string;
    applicationUrl: string;
    connectionString: string;
    id: string;
    isActive: boolean;
    issuer: string;
    name: string;
    validUpto: string;
  };
}

interface dataModel {
  [x: string]: any;
  [index: number]: {
    nodeLabel: string;
    node: string;
    countLabel: string;
    count: number;
  };
}
interface dataUsageRequestModel {
  [x: string]: any;
  [index: number]: {
    name: string;
    requestsCount: number;
    averageProcessTime: number;
    errorsCount: number;
  };
}

interface dataUsageBandwidthModel {
  [x: string]: any;
  [index: number]: {
    name: string;
    bandwidthCount: number;
    averageProcessTime: number;
    errorsCount: number;
  };
}

interface dataUsageStorageModel {
  [x: string]: any;
  [index: number]: {
    name: string;
    storageLength: number;
    averageProcessTime: number;
    errorsCount: number;
  };
}

let dataErrors: dataModel = [];
let dataPerformance: dataModel = [];
let dataUsageRequest: dataUsageRequestModel = [];
let dataUsageBandwidth: dataUsageBandwidthModel = [];
let dataUsageStorage: dataUsageStorageModel = [];

function Dashboard() {
  const date = new Date();
  const defaultMaxDateFormat =
    date.getFullYear() + "-" + pad((date.getMonth() + 1)) + "-" + pad(date.getDate());
  const defaultMinDate: Date = new Date();
  defaultMinDate.setDate(pad(date.getDate() - 7));
  const defaultMinDateFormat =
    defaultMinDate.getFullYear() +
    "-" +
    pad((defaultMinDate.getMonth() + 1)) +
    "-" +
    pad(defaultMinDate.getDate());
  const { useRestRequest } = useDataRequest();
  const [tenants, setTenants] = useState<tenantsModel>([]);
  const [tenantValue, setTenantValue] = useState<string>("root");
  const [tenantName, setTenantName] = useState<string>("Root");
  const [fromDateValue, setFromDateValue] = useState<string>(defaultMinDateFormat);
  const [toDateValue, setToDateValue] = useState<string>(defaultMaxDateFormat);
  const [showModalTenants, setShowModalTenants] = useState<boolean>(false);

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const [rootInformation, setRootInformation] = useState<rootInfoModel>({
    tenantsCount: "",
    expiringTenantsCount: "",
    usersCount: "",
  });
  const [usage, setUsage] = useState<usageModel>([]);
  const [errorsByNode, setErrorsByNode] = useState<errorsByNodeModel>([]);
  const [performanceByNode, setPerformanceByNode] = useState<performanceByNodeModel>([]);
  const [requestsCount, setRequestsCount] = useState<number>(0);
  const [bandwidth, setBandwidth] = useState<string>("0");
  const [storageLength, setStorageLength] = useState<number>(0);
  const [errorByNodeRequestsCount, setErrorByNodeRequestsCount] = useState<number>(0);
  const [performanceByNodeRequestsCount, setPerformanceByNodeRequestsCount] = useState<string>("0");
  const [showFullDataModal, setShowFullDataModal] = useState<boolean>(false);
  const [fullDataModalType, setFullDataModalType] = useState<string>("");
  const [apiLogs, setApiLogs] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<string>("");
  const [selectedNodeName, setSelectedNodeName] = useState<string>("");
  const [thereIsBackModal, setThereIsBackModal] = useState<boolean>(true);
  const handleMoreDetailsData = (e) => {
    setSelectedNode(e.target.getAttribute("data-node"));
    setSelectedNodeName(e.target.getAttribute("data-node-name"));
    setApiLogs(true);
  };
  const handleMoreDetailsDataPie = (e) => {
    if (e.data.isPerformanceNode) {
      setFullDataModalType("performance");
    } else {
      setFullDataModalType("error");
    }
    setSelectedNodeName(e.data.node);
    setSelectedNode(e.data.uniqueId);
    setThereIsBackModal(false);
    setShowFullDataModal(true);
    setApiLogs(true);
  };

  const [reqRootInformation] = useRestRequest({
    path: `api/{ver}/dashboard/root-info`,
    method: "GET",
    jwt: true,
  });
  const [reqTenants] = useRestRequest({
    path: "api/{ver}/tenants",
    method: "GET",
    jwt: true,
  });
  const [reqUsage] = useRestRequest({
    path: `api/{ver}/dashboard/usage`,
    method: "GET",
    jwt: true,
  });

  const [reqErrorsByNode] = useRestRequest({
    path: `api/{ver}/dashboard/errors-by-nodes`,
    method: "GET",
    jwt: true,
  });

  const [reqPerformanceByNode] = useRestRequest({
    path: `api/{ver}/dashboard/performance-by-nodes`,
    method: "GET",
    jwt: true,
  });

  const requestUsage = useCallback(
    async (tenantValue, fromDateValue, toDateValue) => {
      const getUsage = (await reqUsage({
        path: `api/{ver}/dashboard/usage?TenantId=${tenantValue}&from=${fromDateValue}&to=${toDateValue}`,
        method: "GET",
        jwt: true,
      })) as usageModel;
      return getUsage;
    },
    [reqUsage]
  );

  const requestErrorsByNode = useCallback(
    async (tenantValue, fromDateValue, toDateValue) => {
      const getErrorsByNode = (await reqErrorsByNode({
        path: `api/{ver}/dashboard/errors-by-nodes?TenantId=${tenantValue}&from=${fromDateValue}&to=${toDateValue}`,
        method: "GET",
        jwt: true,
      })) as usageModel;
      return getErrorsByNode;
    },
    [reqErrorsByNode]
  );

  const requestPerformanceByNode = useCallback(
    async (tenantValue, fromDateValue, toDateValue) => {
      const getPerformanceByNode = (await reqPerformanceByNode({
        path: `api/{ver}/dashboard/performance-by-nodes?TenantId=${tenantValue}&from=${fromDateValue}&to=${toDateValue}`,
        method: "GET",
        jwt: true,
      })) as usageModel;
      return getPerformanceByNode;
    },
    [reqPerformanceByNode]
  );

  useEffect(() => {
    reqRootInformation()
      .then((response: any) => setRootInformation(response))
      .catch((data: any) => {
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
    reqTenants()
      .then((response: any) => setTenants(response))
      .catch((data: any) => {
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
    setTenantName(localStorage.getItem("defaultTenantsName") || "Root");
    setTenantValue(localStorage.getItem("defaultTenants") || "root");
  }, [reqRootInformation, reqTenants]);

  useEffect(() => {
    let tempRequestsCount = 0;
    let tempBandwidth = 0;
    let tempStorageLength = 0;
    let tempPerformanceByNodeRequestsCount = 0;
    dataUsageRequest = [];
    dataUsageBandwidth = [];
    dataUsageStorage = [];
    usage.map((element) => {
      tempRequestsCount = tempRequestsCount + element.requestsCount;
      tempBandwidth = tempBandwidth + element.bandwidthCount;
      tempStorageLength = tempStorageLength + element.storageLength;
      tempPerformanceByNodeRequestsCount =
        tempPerformanceByNodeRequestsCount + element.totalProcessTime;
      dataUsageRequest.push({
        name:
          pad(new Date(element.day.split("T")[0]).getDate()) +
          "-" +
          pad((new Date(element.day.split("T")[0]).getMonth() + 1)) +
          "-" +
          new Date(element.day.split("T")[0]).getFullYear().toString().slice(-2),
        requestsCount: element.requestsCount,
        averageProcessTime: element.averageProcessTime,
        errorsCount: element.errorsCount,
      });
      dataUsageBandwidth.push({
        name:
          pad(new Date(element.day.split("T")[0]).getDate()) +
          "-" +
          pad((new Date(element.day.split("T")[0]).getMonth() + 1)) +
          "-" +
          new Date(element.day.split("T")[0]).getFullYear().toString().slice(-2),
        bandwidthCount: Number(element.bandwidthCount / 1e9).toFixed(2),
        averageProcessTime: element.averageProcessTime,
        errorsCount: element.errorsCount,
      });
      dataUsageStorage.push({
        name:
          pad(new Date(element.day.split("T")[0]).getDate()) +
          "-" +
          pad((new Date(element.day.split("T")[0]).getMonth() + 1)) +
          "-" +
          new Date(element.day.split("T")[0]).getFullYear().toString().slice(-2),
        storageLength: (element.storageLength / 1e+9).toFixed(2),
        averageProcessTime: element.averageProcessTime,
        errorsCount: element.errorsCount,
      });
      return void 0;
    });
    setPerformanceByNodeRequestsCount(
      (tempPerformanceByNodeRequestsCount * 2.7777777777778e-7).toFixed(2)
    );
    setRequestsCount(tempRequestsCount);
    setBandwidth((tempBandwidth / 1e9).toFixed(2));
    setStorageLength(tempStorageLength);
  }, [usage, requestsCount, bandwidth, storageLength]);

  useEffect(() => {
    let tempErrorsByNodeRequestsCount = 0;
    dataErrors = [];
    errorsByNode.map((element, index) => {
      tempErrorsByNodeRequestsCount = tempErrorsByNodeRequestsCount + element.errorsCount;
      if (index < 10 && element.errorsCount > 0) {
        dataErrors.push({
          name: element.node + " (" + element.requestsCount + " Requests)",
          value: element.errorsCount,
          uniqueId: element.nodeUniqueId,
          node: element.node,
          isPerformanceNode: false,
        });
      }
      return void 0;
    });
    setErrorByNodeRequestsCount(tempErrorsByNodeRequestsCount);
  }, [errorsByNode, errorByNodeRequestsCount]);

  useEffect(() => {
    dataPerformance = [];
    performanceByNode.map((element, index) => {
      if (index < 10) {
        dataPerformance.push({
          name: element.node + " (" + element.requestsCount + " Requests)",
          value: element.averageProcessTime,
          uniqueId: element.nodeUniqueId,
          node: element.node,
          isPerformanceNode: true,
        });
      }
      return void 0;
    });
  }, [performanceByNode, performanceByNodeRequestsCount]);

  const morePreformance = () => {
    setThereIsBackModal(true);
    setShowFullDataModal(true);
    setFullDataModalType("performance");
  };

  const moreErrors = () => {
    setThereIsBackModal(true);
    setShowFullDataModal(true);
    setFullDataModalType("error");
  };

  return (
    <>
      <div className="dashboard-container">
        <div className="row">
          <DashboardCard
            icon={"i-University"}
            text={"Tenants"}
            value={rootInformation.tenantsCount}
          />
          <DashboardCard
            icon={"i-University"}
            text={"Expiring tenants"}
            value={rootInformation.expiringTenantsCount}
          />
          <DashboardCard
            icon={"i-Administrator"}
            text={"Users"}
            value={rootInformation.usersCount}
          />
        </div>
        <DashboardForm
          tenants={tenants}
          tenantValue={tenantValue}
          tenantName={tenantName}
          setTenantValue={setTenantValue}
          setTenantName={setTenantName}
          requestUsage={requestUsage}
          requestErrorsByNode={requestErrorsByNode}
          requestPerformanceByNode={requestPerformanceByNode}
          setUsage={setUsage}
          setErrorsByNode={setErrorsByNode}
          setPerformanceByNode={setPerformanceByNode}
          fromDateValue={fromDateValue}
          setFromDateValue={setFromDateValue}
          toDateValue={toDateValue}
          setToDateValue={setToDateValue}
          defaultMaxDateFormat={defaultMaxDateFormat}
          defaultMinDateFormat={defaultMinDateFormat}
          setShowModalTenants={setShowModalTenants}
        />
        {usage.length > 0 ? (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mb-5">
              <div className="mb-3">
                API usage:{" "}
                <b>
                  <NumericFormat
                    value={requestsCount}
                    thousandSeparator={true}
                    displayType="text"
                  />{" "}
                  total requests
                </b>
              </div>
              <LinearGraph height={500} dataValue={dataUsageRequest} graphType={"requests"} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-5">
              <div className="mb-3">
                Bandwidth usage:{" "}
                <b>
                  <NumericFormat value={bandwidth} thousandSeparator={true} displayType="text" />
                  {" "}GB of total bandwidth
                </b>
              </div>
              <LinearGraph height={500} dataValue={dataUsageBandwidth} graphType={"bandwidth"} />
            </div>
            <div className="col-12 col-lg-4 mb-5">
              <div className="mb-3">
                Storage usage:{" "}
                <b>
                  <NumericFormat
                    value={(storageLength / 1e+9).toFixed(2)}
                    thousandSeparator={true}
                    displayType="text"
                  />{" "}
                  total length
                </b>
              </div>
              <LinearGraph height={500} dataValue={dataUsageStorage} graphType={"storage"} />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          {errorsByNode.length > 0 ? (
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                Errors by API node:{" "}
                <b>
                  {" "}
                  <NumericFormat
                    value={errorByNodeRequestsCount}
                    thousandSeparator={true}
                    displayType="text"
                  />
                  {""} total errors
                </b>
              </div>
              <CakeGraph
                height={500}
                dataValue={dataErrors}
                type={"error"}
                handleMoreDetailsDataPie={handleMoreDetailsDataPie}
              />
              <div className="mt-2 d-flex justify-content-end">
                <button className="btn btn-secondary float-right mb-5" onClick={() => moreErrors()}>
                  Full error list by node...
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {performanceByNode.length > 0 ? (
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                API performance:{" "}
                <b>
                  <NumericFormat
                    value={performanceByNodeRequestsCount}
                    thousandSeparator={true}
                    displayType="text"
                  />{" "}
                  total hours of CPU
                </b>
              </div>
              <CakeGraph
                height={500}
                dataValue={dataPerformance}
                type={"performance"}
                handleMoreDetailsDataPie={handleMoreDetailsDataPie}
              />
              <div className="mt-2 d-flex justify-content-end">
                <button className="btn btn-secondary float-right" onClick={() => morePreformance()}>
                  Full preformance list by node...
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <FullDataModal
        showFullDataModal={showFullDataModal}
        setShowFullDataModal={setShowFullDataModal}
        errorsByNode={errorsByNode}
        performanceByNode={performanceByNode}
        fullDataModalType={fullDataModalType}
        tenantValue={tenantValue}
        tenantName={tenantName}
        fromDateValue={fromDateValue}
        toDateValue={toDateValue}
        apiLogs={apiLogs}
        setApiLogs={setApiLogs}
        handleMoreDetailsData={handleMoreDetailsData}
        selectedNode={selectedNode}
        thereIsBackModal={thereIsBackModal}
        selectedNodeName={selectedNodeName}
      />

      <TenantsModal
        requestId={tenantValue}
        requestName={tenantName}
        showModal={showModalTenants}
        setShowModal={setShowModalTenants}
      />

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />
    </>
  );
}

export default Dashboard;
