import _get from 'lodash/get';
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

interface ConfigContextValues {
    getEnv: (key: string) => string | undefined;
    hasFeature: (key: string) => boolean;
}

export const ConfigContext = React.createContext<ConfigContextValues>({
    getEnv: (key) => undefined,
    hasFeature: (key) => false,
});


export const ConfigProvider = (props: { children: ReactNode }) => {
    const [config, setConfig] = useState<Record<string, string>>();
    const [error, setError] = useState();
    
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(`/${process.env.REACT_APP_CONFIG || 'app.json'}`);
                const json = await response.json();
                setConfig(json);
            } catch(e: any) {
                setError(e.message);
            }
        })();
    }, []);

    const getEnv = useCallback((key: string) => {
        const env = {
            ...process.env,
            ...config,
        };
        return _get(env, key);
    }, [config]);

    const hasFeature = useCallback((key: string) => {
        const features = getEnv('REACT_APP_FEATURES') || '';
        return features.indexOf(key) !== -1;
    }, [getEnv]);

    if (error) return <div className="ConfigLoader">
        <p><strong>Errore di caricamento configurazioni base</strong></p>
        <p>{JSON.stringify(error)}</p>
    </div>;
    if (!config) return <div className="ConfigLoader"><Spinner /></div>;

    return <ConfigContext.Provider value={{
        ...props,
        getEnv,
        hasFeature,
    }}>
        {props.children}
    </ConfigContext.Provider>;
};
