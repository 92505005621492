import ReactEcharts from "echarts-for-react";

const LinearGraph = ({ height, dataValue, graphType }) => {
  const abscissa: any = [];
  const ordinate: any = [];
  dataValue.map((data) => {
    abscissa.push(data.name);
    if (graphType === "requests") {
      ordinate.push(data.requestsCount);
    } else if (graphType === "bandwidth") {
      ordinate.push(data.bandwidthCount);
    } else {
      ordinate.push(data.storageLength);
    }
    return void 0;
  });
  const option = {
    grid: {
      left: "8px",
      right: "8px",
      bottom: "0",
      containLabel: true,
    },
    tooltip: {
      show: true,
      backgroundColor: "#ffffff",
    },
    xAxis: [
      {
        type: "category",
        data: abscissa,
        axisTick: {
          alignWithLabel: true,
        },
        splitLine: {
          show: true,
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
          interval: "auto",
        },
      },
    ],

    series: [
      {
        name:
          graphType === "requests"
            ? "requestsCount"
            : graphType === "bandwidth"
            ? "bandwidthCount"
            : "storageLength",
        data: ordinate,
        label: { show: false, color: "#0168c1" },
        type: "line",
        color: "#bcbbdd",
        smooth: true,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: -2,
            shadowColor: "rgba(0, 0, 0, 0.3)",
          },
        },
      },
    ],
  };

  return <ReactEcharts style={{ height: height }} option={option} />;
};

export default LinearGraph;
