import { useState, useCallback } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest } from "@ai4/data-request";
import Table from "../../../@bootstrap/components/Table";
import LogsFilters from "./LogsFilters";
import Spinner from "../../../@bootstrap/components/Spinner";
import LogsModal from "./modal/LogsModal";
import { formatDate } from "../../../@bootstrap/features/utilities";
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'

function LogsRuntime() {
  const { useRestRequest } = useDataRequest();
  const [requestId, setRequestId] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filtredApplicationLogs, setFiltredApplicationLogs] = useState<Array<any>>([]);
  const [columns, setColumns] = useState<Array<any>>([
    { name: "UniqueId", toggle: true },
    { name: "Datetime", toggle: true },
    { name: "User", toggle: true },
    { name: "Process time", toggle: true },
    { name: "Response Length", toggle: false },
  ]);
  const [paginationValue] = useState<Array<string>>(["10", "25", "30", "50"]);
  const [defaultPage, setDefaultPage] = useState<string>(paginationValue[0]);
  const [paginationTotalCount, setPaginationTotalCount] = useState<string>("");
  const [paginationTotalPages, setPaginationTotalPages] = useState<string>("");
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<string>("1");
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);
  const [tenantValue, setTenantValue] = useState<string>("");
  const [fromDateValue, setFromDateValue] = useState<string>("");
  const [toDateValue, setToDateValue] = useState<string>("");
  const [selectedProcessTimeFrom, setSelectedProcessTimeFrom] = useState<string>("");
  const [selectedProcessTimeTo, setSelectedProcessTimeTo] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [isNoResult, setIsNoResult] = useState<boolean>(false);
  const logsType = "apiRuntimeLogs";

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const apiRequestValues: Array<string> = [];
  apiRequestValues.push(
    tenantValue,
    fromDateValue,
    toDateValue,
    selectedProcessTimeFrom,
    selectedProcessTimeTo,
    selectedUser
  );

  //http rest application-logs
  const [reqRuntimeLogs] = useRestRequest({
    path: `api/{ver}/logs/api-runtime-log?TenantId=${tenantValue}&PageNumber=${paginationCurrentPage}&PageSize=${defaultPage}&Time.From=${fromDateValue}&Time.To=${toDateValue}&User=${selectedUser}&ProcessTime.From=${selectedProcessTimeFrom}&ProcessTime.To=${selectedProcessTimeTo}`,
    method: "GET",
    jwt: true,
  });

  const reqRuntimeLogsPage = useCallback(
    async (
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedProcessTimeFrom,
      selectedProcessTimeTo,
      selectedUser
    ) => {
      const reqRuntimeLogsPage = (await reqRuntimeLogs({
        path: `api/{ver}/logs/api-runtime-log?TenantId=${tenantValue}&PageNumber=${pageNumber}&PageSize=${pageSize}&Time.From=${fromDateValue}&Time.To=${toDateValue}&User=${selectedUser}&ProcessTime.From=${selectedProcessTimeFrom}&ProcessTime.To=${selectedProcessTimeTo}`,
        method: "GET",
        jwt: true,
      })) as any;

      return reqRuntimeLogsPage;
    },
    [reqRuntimeLogs]
  );

  // Function to get response and save in state
  const callRuntimeLogs = (
    pageSize,
    pageNumber,
    tenantValue,
    fromDateValue,
    toDateValue,
    selectedProcessTimeFrom,
    selectedProcessTimeTo,
    selectedUser
  ) => {
    let runtimeLogsPage;
    setIsNoResult(false);
    setSpinnerShow(true);
    reqRuntimeLogsPage(
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedProcessTimeFrom,
      selectedProcessTimeTo,
      selectedUser
    )
      .then((data) => {
        runtimeLogsPage = data.data;
        // setApplicationLogs(runtimeLogsPage);
        setFiltredApplicationLogs(runtimeLogsPage);
        setPaginationTotalCount(data.totalCount);
        setPaginationTotalPages(data.totalPages);
        setPaginationCurrentPage(data.currentPage);
        setSpinnerShow(false);
        if (runtimeLogsPage.length === 0) {
          setIsNoResult(true);
        }
        if (data.currentPage > data.totalPages) {
          callRuntimeLogs(
            pageSize,
            data.totalPages,
            tenantValue,
            fromDateValue,
            toDateValue,
            selectedProcessTimeFrom,
            selectedProcessTimeTo,
            selectedUser
          )
        }
      })
      .catch((data) => {
        setIsNoResult(true);
        setSpinnerShow(false);
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
  };

  // function to show or hide columns
  const onColumnToggle = (el) => {
    setColumns(
      columns.map(function (e) {
        if (e.name === el) {
          e.toggle = !e.toggle;
        }
        return e;
      })
    );
  };

  const handleShowModal = (e) => {
    setRequestId(e.target.getAttribute("data-uniqueid"));
    setShowModal(true);
  };

  // function to set table body from api
  const returnValuesRows = () => {
    return filtredApplicationLogs.map((data, index) => (
      <tr key={index} className="table-modal-button">
        {columns[0].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.uniqueId}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[1].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {formatDate(data.requestTime)}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[2].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.user}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[3].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.processTime}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[4].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.responseLength}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
      </tr>
    ));
  };

  return (
    <>
      <LogsFilters
        logsType={logsType}
        hasApiFilters={true}
        tenantValue={tenantValue}
        setTenantValue={setTenantValue}
        fromDateValue={fromDateValue}
        setFromDateValue={setFromDateValue}
        toDateValue={toDateValue}
        setToDateValue={setToDateValue}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        selectedProcessTimeFrom={selectedProcessTimeFrom}
        setSelectedProcessTimeFrom={setSelectedProcessTimeFrom}
        selectedProcessTimeTo={selectedProcessTimeTo}
        setSelectedProcessTimeTo={setSelectedProcessTimeTo}
        callApiGet={callRuntimeLogs}
        paginationCurrentPage={paginationCurrentPage}
        defaultPage={defaultPage}
        isNoResult={isNoResult}
        apiRequestValues={apiRequestValues}
      />

      <div className={returnValuesRows().length > 0 ? "logs-container" : "d-none"}>
        <div className="logs-container__label">Runtime Logs</div>
        <Table
          filters={false}
          columns={columns}
          onColumnToggle={onColumnToggle}
          returnValuesRows={returnValuesRows}
          paginationValue={paginationValue}
          defaultPage={defaultPage}
          setDefaultPage={setDefaultPage}
          callApiGet={callRuntimeLogs}
          paginationTotalCount={paginationTotalCount}
          paginationTotalPages={paginationTotalPages}
          paginationCurrentPage={paginationCurrentPage}
          apiRequestValues={apiRequestValues}
        />
      </div>

      <LogsModal
        logsType={logsType}
        tenantValue={tenantValue}
        requestId={requestId}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />

      <Spinner show={spinnerShow} />
    </>
  );
}

export default LogsRuntime;
