import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import { gql, useAuthRequest, useDataRequest, useFormViewer } from '@ai4/data-request';
import { Formatters } from '@ai4/records-management';
import $schema from './registration.schema.json';
import { addDateColumns } from 'src/@bootstrap/components/Crud/Crud';
let schema = $schema as SchemaTypes.Schema;

export const useModule = () => {
	const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
		path: 'rest/v1/sso/tenants-registrations',
		method: 'GET',
		jwt: true,
	});
	const [execCreateTenant, resCreateTenant] = useRestRequest({
		path: 'rest/v1/sso/tenant-creation',
		method: 'POST',
		jwt: true,
	});
	useEffect(() => {
		reqList();
	}, []);
	const { transformData } = useFormViewer({});

	const createTenant = useCallback(async (row) => {
		const { uniqueId, } = row;
		try {
			await execCreateTenant({
				path: `rest/sso/v1/tenant-creation`,
				data: {
					registrationRequestUniqueId: uniqueId
				},
			});
			await reqList();
			alert('Tenant creato correttamente.')
		} catch (e) {
			console.log(e);
		}
	}, []);

	const rows = resList.data || [];

	return {
		data: rows,
		schema,
		actions: {
		},
		results: {
		},
		features: {
			creation: false
		},
		extra: {
			hideEditColumns: true,
		},
		table: {
			columns: [
				...addDateColumns([
					{
						dataField: 'uniqueId',
						text: 'ID',
						hidden: true,
					},
					{
						dataField: 'tenantId',
						text: 'Tenant ID',
					},
					{
						dataField: 'email',
						text: 'E-mail',
					},
				]),
				{
					dataField: 'waitingForPayment',
					formatter: (val, row) => { return val ? <div style={{ textAlign: 'right' }}><button type="button" className='btn btn-secondary' onClick={() => { createTenant(row); }}>Crea tenant</button></div> : `` }
				},
			]
		}
	};
}
