import { useState, useCallback, useEffect } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest } from "@ai4/data-request";
import Table from "../../../../@bootstrap/components/Table";
import Spinner from "../../../../@bootstrap/components/Spinner";
import MoreDetailDataModal from "./MoreDetailDataModal";
import { formatDate } from "../../../../@bootstrap/features/utilities";
import ErrorApiAlert from '../../../../@bootstrap/components/alert/ErrorApiAlert'

function LogsApiList(props) {
  const {
    tenantValue,
    fromDateValue,
    toDateValue,
    selectedNode,
    setApiLogs,
    thereIsBackModal,
    fullDataModalType,
    moreDetailsData,
    setMoreDetailsData,
  } = props;
  const { useRestRequest } = useDataRequest();
  const [requestId, setRequestId] = useState<string>("");
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);
  const [valuePerColumnApiLogs, setValuePerColumnApiLogs] = useState<Array<any>>([]);
  const [columns, setColumns] = useState<Array<any>>([
    { name: "uniqueId", toggle: false },
    { name: "requestTime", toggle: true },
    { name: "domain", toggle: false },
    { name: "node", toggle: true },
    { name: "processTime", toggle: true },
    { name: "success", toggle: true },
    { name: "user", toggle: false },
    { name: "request length", toggle: false },
    { name: "response length", toggle: false },
  ]);
  const [paginationValue] = useState<Array<any>>(["10", "25", "30", "50"]);
  const [defaultPage, setDefaultPage] = useState<string>(paginationValue[0]);
  const [paginationTotalCount, setPaginationTotalCount] = useState<string>("");
  const [paginationTotalPages, setPaginationTotalPages] = useState<string>("");
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<string>("1");
  const [isNoResult, setIsNoResult] = useState<boolean>(false);

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const apiRequestValues: Array<any> = [];
  apiRequestValues.push(tenantValue, fromDateValue, toDateValue, selectedNode, fullDataModalType);

  //http rest application-logs
  const [reqApiLogs] = useRestRequest({
    path: `api/v1/logs/api-log?TenantId=${tenantValue}&PageNumber=${paginationCurrentPage}&PageSize=${defaultPage}&Time.From=${fromDateValue}&Time.To=${toDateValue}&NodeUniqueId=${selectedNode}&Success=${fullDataModalType === "performance" ? "true" : "false"
      }`,
    method: "GET",
    jwt: true,
  });

  const requestApiLogs = useCallback(
    async (
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedNode,
      fullDataModalType
    ) => {
      const reqApiLogsPage = (await reqApiLogs({
        path: `api/v1/logs/api-log?TenantId=${tenantValue}&PageNumber=${pageNumber}&PageSize=${pageSize}&Time.From=${fromDateValue}&Time.To=${toDateValue}&NodeUniqueId=${selectedNode}&Success=${fullDataModalType === "performance" ? "true" : "false"
          }`,
        method: "GET",
        jwt: true,
      })) as any;
      return reqApiLogsPage;
    },
    [reqApiLogs]
  );

  // Function to get response and save in state
  const callApiLogs = (
    pageSize,
    pageNumber,
    tenantValue,
    fromDateValue,
    toDateValue,
    selectedNode,
    fullDataModalType
  ) => {
    let apiLogsPage;
    setIsNoResult(false);
    setSpinnerShow(true);
    requestApiLogs(
      pageSize,
      pageNumber,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedNode,
      fullDataModalType
    )
      .then((data) => {
        apiLogsPage = data.data;
        setValuePerColumnApiLogs(apiLogsPage);
        setPaginationTotalCount(data.totalCount);
        setPaginationTotalPages(data.totalPages);
        setPaginationCurrentPage(data.currentPage);
        setSpinnerShow(false);
        if (apiLogsPage.length === 0) {
          setIsNoResult(true);
        }
      })
      .catch((data) => {
        setIsNoResult(true);
        setSpinnerShow(false);
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
  };

  // function to show or hide columns
  const onColumnToggle = (el) => {
    setColumns(
      columns.map(function (e) {
        if (e.name === el) {
          e.toggle = !e.toggle;
        }
        return e;
      })
    );
  };

  useEffect(() => {
    callApiLogs(
      defaultPage,
      paginationCurrentPage,
      tenantValue,
      fromDateValue,
      toDateValue,
      selectedNode,
      fullDataModalType
    );
  }, [tenantValue, fromDateValue, toDateValue, selectedNode, fullDataModalType]);

  const handleShowModal = (e) => {
    setRequestId(e.target.getAttribute("data-uniqueid"));
    setMoreDetailsData(true);
  };

  // function to set table body from api
  const returnValuesRows = () => {
    return valuePerColumnApiLogs.map((data, index) => (
      <tr key={index} className="table-modal-button">
        {columns[0].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.uniqueId}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[1].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {formatDate(data.requestTime)}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[2].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.domain}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[3].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.node}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[4].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            <div
              className="process-time"
              data-uniqueid={data.uniqueId}
              onClick={(e) => {
                handleShowModal(e);
              }}
            >
              {data.processTime}
              <span
                data-uniqueid={data.uniqueId}
                onClick={(e) => {
                  handleShowModal(e);
                }}
                className={
                  data.processTime < 100
                    ? "badge badge-grey"
                    : data.processTime < 250
                      ? "badge badge-yellow"
                      : "badge badge-red"
                }
              >
                {data.processTime < 100 ? "Good" : data.processTime < 250 ? "Med" : "Bad"}
              </span>
            </div>
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[5].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.success ? "True" : "False"}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[6].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.user}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[7].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.requestLength}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
        {columns[8].toggle ? (
          <td
            data-uniqueid={data.uniqueId}
            onClick={(e) => {
              handleShowModal(e);
            }}
          >
            {data.responseLength}
          </td>
        ) : (
          <td style={{ display: "none" }}></td>
        )}
      </tr>
    ));
  };

  return (
    <>
      {!moreDetailsData ? (
        <>
          {thereIsBackModal ? (
            <button className="btn btn-secondary mb-4" onClick={() => setApiLogs(false)}>
              back
            </button>
          ) : (
            ""
          )}
          <div className="logs-container">
            <div className="logs-container__label">Api Logs</div>
            {!isNoResult ? (
              <Table
                filters={false}
                columns={columns}
                onColumnToggle={onColumnToggle}
                returnValuesRows={returnValuesRows}
                paginationValue={paginationValue}
                defaultPage={defaultPage}
                setDefaultPage={setDefaultPage}
                callApiGet={callApiLogs}
                paginationTotalCount={paginationTotalCount}
                paginationTotalPages={paginationTotalPages}
                paginationCurrentPage={paginationCurrentPage}
                apiRequestValues={apiRequestValues}
              />
            ) : (
              <div>Nessun risultato trovato</div>
            )}
          </div>
        </>
      ) : (
        <MoreDetailDataModal
          tenantValue={tenantValue}
          requestId={requestId}
          setMoreDetailsData={setMoreDetailsData}
        />
      )}

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />

      <Spinner show={spinnerShow} />
    </>
  );
}

export default LogsApiList;
