import { useEffect, useState, useCallback } from "react";
import "@ai4/form-builder/dist/index.css";
import { useDataRequest } from "@ai4/data-request";
import ApplicationLogs from "./ApplicationLogs";
import LogsTabsTrace from "./modal/LogsTabsTrace";
import Spinner from "../../../@bootstrap/components/Spinner";
import ErrorApiAlert from '../../../@bootstrap/components/alert/ErrorApiAlert'

function LogsTrace() {
  const { useRestRequest } = useDataRequest();
  const [requestId, setRequestId] = useState<string>("");
  const [tenantValue, setTenantValue] = useState<string>("");
  const [isApiLog, setIsApiLog] = useState<boolean>(false);
  const [hasApiFilters] = useState<boolean>(false);
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);
  const [isNoResult, setIsNoResult] = useState<boolean>(false);
  const [applicationLogs, setApplicationLogs] = useState<Array<any>>([]);
  const [paginationTotalCount, setPaginationTotalCount] = useState<string>("");
  const [paginationTotalPages, setPaginationTotalPages] = useState<string>("");
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<string>("1");
  const [logTraceApi, setLogTraceApi] = useState<boolean>(false);
  const [isApplicationFilterActive, setIsApplicationFilterActive] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [isApplicationApiEnd, setIsApplicationApiEnd] = useState<boolean>(false);
  const [isTraceApiEnd, setIsTraceApiEnd] = useState<boolean>(false);

  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  //http rest application-logs
  const [reqApplicationLogs] = useRestRequest({
    path: `api/{ver}/logs/application-logs?TenantId=${tenantValue}&PageNumber=1&PageSize=10`,
    method: "GET",
    jwt: true,
  });

  const [reqTenants, resTenants] = useRestRequest({
    path: "api/{ver}/tenants",
    method: "GET",
    jwt: true,
  });

  const [reqRuntimeLogs, resRuntimeLogs] = useRestRequest({
    path: `api/{ver}/logs/api-runtime-log/${requestId}?tenantId=${tenantValue}`,
    method: "GET",
    jwt: true,
  });

  const [reqApiLog, resApiLog] = useRestRequest({
    path: `api/{ver}/logs/api-log/${requestId}?tenantId=${tenantValue}`,
    method: "GET",
    jwt: true,
  });

  useEffect(() => {
    reqTenants();
  }, [reqTenants]);

  const requestApplicationLogs = useCallback(async (tenantValue) => {
    const reqApplicationLogsPage = (await reqApplicationLogs({
      path: `api/{ver}/logs/application-logs?TenantId=${tenantValue}&PageNumber=1&PageSize=10`,
      method: "GET",
      jwt: true,
    })) as any;
    return reqApplicationLogsPage;
  }, [reqApplicationLogs]);

  // Function to get response and save in state
  const callApplicationLogs = (tenantValue) => {
    setLogTraceApi(true);
    setSpinnerShow(true);
    setIsTraceApiEnd(false);
    let applicationLogsPage;
    requestApplicationLogs(tenantValue)
      .then((data) => {
        applicationLogsPage = data.data;
        setIsApplicationFilterActive(true);
        setApplicationLogs(applicationLogsPage);
        setSpinnerShow(false);
        setPaginationTotalCount(data.totalCount);
        setPaginationTotalPages(data.totalPages);
        setPaginationCurrentPage(data.currentPage);
        setLogTraceApi(false);
        setIsApplicationFilterActive(false);
        setIsDisabledButton(false);
        setIsTraceApiEnd(true);
        if (applicationLogsPage.length === 0) {
          setIsApplicationFilterActive(false);
          setIsDisabledButton(true);
        }
      })
      .catch((data) => {
        setIsDisabledButton(true);
        setIsApplicationFilterActive(false);
        setSpinnerShow(false);
        setLogTraceApi(false);
        setIsTraceApiEnd(true);
        setIsErrorShow(true);
        setErrorMessage(data.messages);
        setErrorCode(data.requestId);
      });
  };

  const requestRuntimeLogs = useCallback(async (requestId, tenantValue) => {
    const reqRuntimeLogsDetail = (await reqRuntimeLogs({
      path: `api/{ver}/logs/api-runtime-log/${requestId}?tenantId=${tenantValue}`,
      method: "GET",
      jwt: true,
    })) as any;
    return reqRuntimeLogsDetail;
  }, [reqRuntimeLogs]);

  const requesApiLogs = useCallback(async (requestId, tenantValue) => {
    const reqApiLogDetail = (await reqApiLog({
      path: `api/{ver}/logs/api-log/${requestId}?tenantId=${tenantValue}`,
      method: "GET",
      jwt: true,
    })) as any;
    setIsApiLog(true);
    return reqApiLogDetail;
  }, [reqApiLog]);

  const applyFilter = (requestId, tenantValue) => {
    setIsApplicationApiEnd(false);
    setSpinnerShow(true);
    setIsNoResult(false);
    requestRuntimeLogs(requestId, tenantValue)
      .then((data) => {
        callApplicationLogs(tenantValue);
        if (Object.keys(data).length === 0) {
          requesApiLogs(requestId, tenantValue)
            .then(() => {
              setIsApplicationApiEnd(true);
            })
            .catch((data) => {
              setIsApplicationApiEnd(true);
              setIsErrorShow(true);
              setErrorMessage(data.messages);
              setErrorCode(data.requestId);
            });
        } else {
          setIsApplicationApiEnd(true);
          setSpinnerShow(false);
        }
      })
      .catch(() => {
        callApplicationLogs(tenantValue);
        requesApiLogs(requestId, tenantValue)
          .then(() => {
            setIsApplicationApiEnd(true);
          })
          .catch((data) => {
            setIsApplicationApiEnd(true);
            setIsErrorShow(true);
            setErrorMessage(data.messages);
            setErrorCode(data.requestId);
          });
        setSpinnerShow(false);
      });
  };

  const tenants = resTenants.data;
  const details: any = isApiLog ? resApiLog : resRuntimeLogs;
  useEffect(() => {
    if (isTraceApiEnd && isApplicationApiEnd) {
      if (typeof details.data === "undefined" && applicationLogs.length === 0) {
        setIsNoResult(true);
      }
    }
  }, [isTraceApiEnd, isApplicationApiEnd, details, applicationLogs]);

  return (
    <>
      {/* Filters trace */}
      <div className="logTraceFilter-container">
        <div className="row mb-4">
          <div className="col-12 col-md-7">
            <div className="row align-items-center">
              <div className="col-3 col-md-2">
                <label htmlFor="">Tenant</label>{" "}
              </div>
              <div className="col-9 col-md-10">
                <select name="tenants" id="tenantsId" onChange={(e) => setTenantValue(e.target.value)}>
                  <option value="">--Select a tenant--</option>
                  {typeof tenants !== "undefined" && tenants !== null
                    ? tenants.map((tenant, index) => {
                      return <option value={tenant.id} key={index}>{tenant.name}</option>;
                    })
                    : ""}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-7 mb-5">
            <div className="row align-items-center">
              <div className="col-3 col-md-2">
                <label htmlFor="">RequestId</label>
              </div>
              <div className="col-9 col-md-10">
                <input
                  type="text"
                  id=""
                  className=""
                  name=""
                  value={requestId}
                  onChange={(e) => setRequestId(e.target.value)}
                />
              </div>
            </div>
            {isNoResult ? <div className="no-result mt-4">No result found</div> : ""}
          </div>
          <div className="col-12 col-md-5 mb-5">
            <div className="button-container">
              <button
                className="btn btn-secondary"
                onClick={() => applyFilter(requestId, tenantValue)}
                disabled={requestId === "" || tenantValue === ""}
              >
                Apply filters
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* TABS */}
      <LogsTabsTrace details={details} isApiLog={isApiLog} />
      {/* Application logs */}
      <ApplicationLogs
        logTraceApi={logTraceApi}
        logTrace={true}
        hasApiFilters={hasApiFilters}
        tenantValueTrace={tenantValue}
        applicationLogsTrace={applicationLogs}
        paginationTotalCountTrace={paginationTotalCount}
        paginationTotalPagesTrace={paginationTotalPages}
        paginationCurrentPageTrace={paginationCurrentPage}
        isTraceFilterActive={isApplicationFilterActive}
        isTraceDisabledButton={isDisabledButton}
      />

      <ErrorApiAlert
        errors={errorMessage}
        errorCode={errorCode}
        isErrorShow={isErrorShow}
        setIsErrorShow={setIsErrorShow}
        setErrorMessage={setErrorMessage}
        setErrorCode={setErrorCode}
      />

      <Spinner show={spinnerShow} />
    </>
  );
}

export default LogsTrace;
